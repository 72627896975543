<template>
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-12 p-0">
                <div class="login-card">
                    <div>
                        <div></div>
                        <div class="login-main">
                            <form class="theme-form" @submit.prevent="">
                                <h4 class="text-center">Sign in to account</h4>
                                <p class="text-center">Enter your email & password to login</p>
                                <div class="form-group">
                                    <label class="col-form-label">Email Address</label>
                                    <input v-model="email" class="form-control" type="email"
                                        placeholder="Test@gmail.com">
                                </div>
                                <div class="form-group">
                                    <label class="col-form-label">Password</label>
                                    <div class="form-input position-relative">
                                        <input v-model="password" :type="type" class="form-control"
                                            name="login[password]" placeholder="*********">
                                        <div class="show-hide"><span class="show" @click="showPassword"> </span></div>
                                    </div>
                                </div>
                                <div class="form-group mb-0">
                                    <div class="checkbox p-0">
                                        <input id="checkbox1" type="checkbox">
                                        <label class="text-muted" for="checkbox1">Remember password</label>
                                    </div><a class="link"><router-link to="/authentication/forgetpassword">Forgot
                                            password?</router-link></a>
                                    <div class="text-end mt-3">
                                        <button class="btn btn-primary btn-block w-100" type="submit"
                                            @click="doLogin">Sign
                                            in </button>
                                    </div>
                                </div>
                                <div class="login-social-title">
                                    <h6>Or Sign in with </h6>
                                </div>
                                <div class="form-group">
                                    <ul class="login-social">
                                        <li><a href="https://www.linkedin.com" target="_blank"><vue-feather
                                                    type="linkedin"></vue-feather></a></li>
                                        <li><a href="https://twitter.com" target="_blank"><vue-feather
                                                    type="twitter"></vue-feather></a></li>
                                        <li><a href="https://www.facebook.com" target="_blank"><vue-feather
                                                    type="facebook"></vue-feather></a></li>
                                        <li><a href="https://www.instagram.com" target="_blank"><vue-feather
                                                    type="instagram"></vue-feather></a></li>
                                    </ul>
                                </div>
                                <p class="mt-4 mb-0 text-center">Don't have account?<router-link class="ms-2"
                                        to="/auth/register">Create Account</router-link></p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import { createToaster } from "@meforma/vue-toaster";
import { useRouter } from 'vue-router';
import { useProjectStore } from "@/store/project";


const baseUrl = process.env.VUE_APP_BASE_URL;
const store = useProjectStore();
const toaster = createToaster({});
const router = useRouter();
const type = ref<string>('password');

const email = ref<string>("");
const password = ref<string>("");

function showPassword() {
    if (type.value === 'password') {
        type.value = 'text';
    } else {
        type.value = 'password';
    }
}

async function doLogin() {
    console.log('Attempting to login...');
    try {
        const response = await fetch(`${baseUrl}api/user/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email.value,
                password: password.value
            })
        });

        console.log('Received response:', response);

        if (response.ok) {
            const data = await response.json();
            console.log('Login successful:', data);


            // Store token in localStorage
            localStorage.setItem('token', data.token);
            console.log('Token stored:', data.token);
            // Optionally, store user data or other information
            localStorage.setItem('user', JSON.stringify(data.user));

            // Redirect to home page or desired route
            router.replace('/');
        } else {
            console.log('Login failed with response:', response);
            toaster.show('Opps... Invalid email and password ', { theme: 'outline', position: 'top-right', icon: 'times', type: 'error', duration: 2000 });
        }

        // Example: Set sidebar type in localStorage
        localStorage.setItem("SidebarType", 'compact-wrapper');
    } catch (error) {
        console.error('Network error:', error);
        // Handle network error
    }
}
</script>
