<script lang="js" setup>
import { ref, onMounted, Ref } from "vue";
import { fetchGetApi } from "@/util/api";
import jsonToExcel from '@/util/makeExcel';
import jsonToPdf from '@/util/makeExcel';


const allData = ref([]);
import Swal from 'sweetalert2';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { defineAsyncComponent } from 'vue';

const modalCode = defineAsyncComponent(() => import("@/components/roles/modalCode.vue"));
const elementsPerPage = ref(25);
const currentPage = ref(1);
const filterQuery = ref("");
const toast = useToast();

onMounted(async () => {
    getData();
});
function getData(){
    fetchGetApi('api/operation/get/training/security').then((response) => {
        console.log(response.data);
        if (response && response.data) {
            const sites = response.data.training
            console.log(sites);
            allData.value = sites;
            console.log(allData.value);
        } else {
            toast.error('Failed to fetch data')
        }
    });
}
function downloadExcel() {
    jsonToExcel(kypList.value, 'kyp.xlsx');
    // jsonToPdf(kypList.value, 'kyp.pdf');
}



function num_pages() {
    const query = filterQuery.value.toLowerCase();

    // Ensure allData.value is an array
    const data = Array.isArray(allData.value) ? allData.value : [];

    const filteredData = data.filter(kyt =>
        kyt.siteId.toLowerCase().includes(query) ||
        kyt.siteId.toLowerCase().includes(query)
    );

    return Math.ceil(filteredData.length / elementsPerPage.value);
}

function change_page(page) {
    currentPage.value = page;
}

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

function nextPage() {
    if (currentPage.value < num_pages()) {
        currentPage.value++;
    }
}

const startDate = ref("");
const endDate = ref("");
const sites = ref(["Site 11"]);
const selectedSite = ref([]);

function formatClientDetails(clientDetails) {
    return clientDetails.map(d => `${d.name}, ${d.designation}`).join(', ');
}
</script>

<template>
    <Breadcrumbs main="Apps" title="Training and briefing Report" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row card card-body">
                <div class="table-responsive product-table custom-scrollbar">
                    <form class="no-footer">
                        <div class="mb-3 row justify-content-between">
                            <div class="col-xs-3 col-sm-auto">
                                <label class="col-xs-12 col-sm-auto col-form-label">Date range:</label>
                                <div class="d-flex align-items-center">
                                    <input type="date" class="form-control" v-model="startDate" style="width: 150px;">
                                    <span class="mx-1">to</span>
                                    <input type="date" class="form-control" v-model="endDate" style="width: 150px;">
                                </div>
                            </div>
                            <div class="col-xs-3 col-sm-auto">
                                <label for="area-select" class="col-xs-12 col-sm-auto col-form-label">Search by
                                    area:</label>
                                <multiselect id="area-select" v-model="selectedSite" :options="sites" :multiple="true"
                                    :searchable="true" placeholder="Select areas" label="name" track-by="name"
                                    class="form-control"></multiselect>
                            </div>
                            <div class="col-xs-3 ms-3 mt-3 col-sm-auto d-flex align-items-center">
                                <button class="btn btn-primary" @click="search">
                                    <i class="fas fa-search"></i>
                                </button>
                            </div>
                            <div class="col-xs-3 col-sm-auto">
                                <button type="button" class="btn btn-primary" @click="downloadExcel">Download
                                    Excel</button>
                            </div>
                        </div>
                        <table class="display table-striped table-hover table-bordered table" id="basic-1">
                            <thead>
                                <tr>
                                    <th class="sorting_asc" scope="col" style="width: 50px;">SL</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Date</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Site Name</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Briefer name</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Depertment</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Topics</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Duration</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Problem</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Solution</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Client</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Name & Designation</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Emp attended</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Emp no attended</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Not attended this month</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Employee name</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Sub category</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Action taken</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Photos</th>
                                </tr>
                            </thead>
                            <tbody>
            <tr v-for="(item, index) in allData" :key="item.id">
                <td>{{ index + 1 }}</td>
                <td>{{ item.createdAt }}</td>
                <td>{{ item.siteName }}</td>
                <td>{{ item.briefer.name }}</td>
                <td>{{ item.securityTraining.department }}</td>
                <td>{{ item.securityTraining.topics.join(', ') }}</td>
                <td>{{ item.securityTraining.trainingDuration }}</td>
                <td>{{ item.securityTraining.problem }}</td>
                <td>{{ item.securityTraining.solution }}</td>
                <td>{{ item.securityTraining.clientPresent }}</td>
                <td>{{ formatClientDetails(item.securityTraining.clientDetails) }}</td>
                <td>{{ item.presentEmployees.map(d=>d.name).join(',') }}</td>
                <td>{{ item.absentEmployees.map(d=>d.name).join(',') }}</td>
                <td>{{ item.notAttendedThisMonth }}</td>
                <td>{{ item.employeeName }}</td>
                <td>{{ item.subCategory.name }}</td>
                <td>{{ item.employeeName }}</td>
                <td>{{ item.employeeName }}</td>
            </tr>
                            </tbody>
                        </table>

                        <ul class="pagination py-2 justify-content-end pagination-primary">
                            <li class="page-item" @click="previousPage">
                                <a class="page-link">Previous</a>
                            </li>
                            <li class="page-item" v-for="i in num_pages()" :key="i"
                                :class="[i == currentPage ? 'active' : '']" @click="change_page(i)">
                                <a class="page-link">{{ i }}</a>
                            </li>
                            <li class="page-item" @click="nextPage">
                                <a class="page-link">Next</a>
                            </li>
                        </ul>
                    </form>
                    <modalCode />
                </div>
            </div>
        </div>
    </div>
</template>
