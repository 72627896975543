<template>
    <Breadcrumbs main="Employee List" title="AllEmployee List" />
    <div class="container-fluid">
        <div class="email-wrap">
            <div class="row">
                <all-employee-list-main />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const AllEmployeeListMain = defineAsyncComponent(() => import("@/components/employee-list/all-employee-list/all-employee-list-table.vue"))
</script>   