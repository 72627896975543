<script lang="js" setup>
import { ref, onMounted, Ref } from "vue";
import { fetchGetApi } from "@/util/api";
import jsonToExcel from '@/util/makeExcel';
import jsonToPdf from '@/util/makeExcel';


const allData = ref([]);
import Swal from 'sweetalert2';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { defineAsyncComponent } from 'vue';

const modalCode = defineAsyncComponent(() => import("@/components/roles/modalCode.vue"));
const elementsPerPage = ref(25);
const currentPage = ref(1);
const filterQuery = ref("");
const toast = useToast();

onMounted(async () => {
    fetchGetApi('api/cdw/getAllCdw').then((response) => {
        console.log(response.data);
        if (response && response.data && response.data.length > 0) {
            const sites = response.data
            console.log(sites);
            allData.value = sites;
            console.log(allData.value);
        } else {
            toast.error('Failed to fetch data')
        }
    });
});
function downloadExcel() {
    jsonToExcel(kypList.value, 'kyp.xlsx');
    // jsonToPdf(kypList.value, 'kyp.pdf');
}



function num_pages() {
    const query = filterQuery.value.toLowerCase();

    // Ensure allData.value is an array
    const data = Array.isArray(allData.value) ? allData.value : [];

    const filteredData = data.filter(kyt =>
        kyt.siteId.toLowerCase().includes(query) ||
        kyt.siteId.toLowerCase().includes(query)
    );

    return Math.ceil(filteredData.length / elementsPerPage.value);
}

function change_page(page) {
    currentPage.value = page;
}

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

function nextPage() {
    if (currentPage.value < num_pages()) {
        currentPage.value++;
    }
}

const kypList = ref([
    {
        siteName: "Site 1",
        address: "Dhaka",
        totalManpower: 10,
        full: 5,
        vacant: 2,
        vacantReliver: 1,
        change: 1,
        absent: 1,
        managedWithOther: 1,
        managedWithOT: 1,
        otherSiteName: "Site 2",
        notManaged: 1,
        criticalSafe: "Critical"
    },

]);
</script>

<template>
    <Breadcrumbs main="Apps" title="Kyp" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row card card-body">
                <div class="table-responsive product-table custom-scrollbar">
                    <form class="no-footer">
                        <div class="mb-3 row justify-content-between">
                            <div class="col-xs-3 col-sm-auto">
                                <label for="table-complete-search"
                                    class="col-xs-12 col-sm-auto col-form-label">Search site:</label>
                                <input id="table-complete-search" type="text" class="form-control" name="searchTerm"
                                    v-model="filterQuery">
                            </div>
                            <div class="col-xs-3 col-sm-auto">
                                <button type="button" class="btn btn-primary" @click="downloadExcel">Download Excel</button>
                            </div>
                        </div>
                        <table class="display table-striped table-hover table-bordered table" id="basic-1">
                            <thead>
                                <tr>
                                    <th class="sorting_asc" scope="col" style="width: 50px;">SL</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Site Name</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Address</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Total manpoower</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Full</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Vacant</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Vacant Reliver</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Change</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Absent</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Managed with other</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Managed with OT</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Other site name</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Not managed</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Critical/Safe</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(site, index) in kypList" :key="site.id">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ site.siteName }}</td>
                                    <td>{{ site.address }}</td>
                                    <td>{{ site.totalManpower }}</td>
                                    <td>{{ site.full }}</td>
                                    <td>{{ site.vacant }}</td>
                                    <td>{{ site.vacantReliver }}</td>
                                    <td>{{ site.change }}</td>
                                    <td>{{ site.absent }}</td>
                                    <td>{{ site.managedWithOther }}</td>
                                    <td>{{ site.managedWithOT }}</td>
                                    <td>{{ site.otherSiteName }}</td>
                                    <td>{{ site.notManaged }}</td>
                                    <td>{{ site.criticalSafe }}</td>
                                </tr>
                            </tbody>
                        </table>

                        <ul class="pagination py-2 justify-content-end pagination-primary">
                            <li class="page-item" @click="previousPage">
                                <a class="page-link">Previous</a>
                            </li>
                            <li class="page-item" v-for="i in num_pages()" :key="i"
                                :class="[i == currentPage ? 'active' : '']" @click="change_page(i)">
                                <a class="page-link">{{ i }}</a>
                            </li>
                            <li class="page-item" @click="nextPage">
                                <a class="page-link">Next</a>
                            </li>
                        </ul>
                    </form>
                    <modalCode />
                </div>
            </div>
        </div>
    </div>
</template>
