<template>
    <div class="card">
        <div class="card-header">
            <h5>Employee Job Life (EJL)</h5>
        </div>
        <div class="card-body">
            <div class="col-md-12 w-full">
                <!-- Employee Basic Info -->
                <div class="col-md-12">
                    <div class="mb-4">
                        <div class="employee-photo mb-3">
                            <img :src="employee.photoUrl || 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsEJHmI0MlIGvH9CYkbsLEWQ5_ee8Qtl5V-Q&s'" alt="Employee Photo"
                                class="img-thumbnail" style="max-width: 200px">
                        </div>
                        <div class="employee-details">
                            <div class="mb-2">
                                <strong>Black listed:</strong>
                                {{ employee.blacklisted ? 'Yes' : 'No' }}
                                <span v-if="employee.blacklisted" class="text-danger ms-2">
                                    ({{ employee.blacklistReason }})
                                </span>
                            </div>
                            <div class="mb-2"><strong>Name:</strong> {{ employee.name }}</div>
                            <div class="mb-2"><strong>DOJ:</strong> {{ formatDate(employee.doj) }}</div>
                            <div class="mb-2"><strong>DOB:</strong> {{ formatDate(employee.dob) }}</div>
                            <div class="mb-2"><strong>SIDDAR Date:</strong> {{ formatDate(employee.siddarDate) }}</div>
                            <div class="mb-2"><strong>Reason:</strong> {{ employee.reason }}</div>
                            <div class="mb-2"><strong>Job left:</strong> {{ employee.jobLeft }}</div>
                        </div>
                    </div>

                    <!-- Document Downloads -->
                    <div class="document-downloads mb-4">
                        <h6>Documents</h6>
                        <div class="list-group">
                            <button class="list-group-item list-group-item-action" @click="downloadDocument('aadhar')"
                                :disabled="!employee.documents.aadhar">
                                Aadhar Card {{ getDocumentStatus('aadhar') }}
                            </button>
                            <button class="list-group-item list-group-item-action" @click="downloadDocument('pan')"
                                :disabled="!employee.documents.pan">
                                Pan Card {{ getDocumentStatus('pan') }}
                            </button>
                            <button class="list-group-item list-group-item-action" @click="downloadDocument('voter')"
                                :disabled="!employee.documents.voter">
                                Voter Card {{ getDocumentStatus('voter') }}
                            </button>
                            <button class="list-group-item list-group-item-action" @click="downloadDocument('passbook')"
                                :disabled="!employee.documents.passbook">
                                Bank Passbook {{ getDocumentStatus('passbook') }}
                            </button>
                        </div>
                    </div>
                </div>

                <!-- Documents List -->
                <div class="col-md-12">
                    <h6>List of documents</h6>
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Joining form</th>
                                    <th>Declaration</th>
                                    <th>Documents scans</th>
                                    <th>PV doc sent</th>
                                    <th>PV doc return</th>
                                    <th>ESIC document</th>
                                    <th>ESIC signed</th>
                                    <th>Resignation/termination slip</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td v-for="doc in employee.documentsList" :key="doc.type">
                                        <i :class="getDocumentIcon(doc.status)"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- Penalty and Warning Slips -->
                    <h6 class="mt-4">Penalty and warning slips</h6>
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Site name</th>
                                    <th>Penalty slip</th>
                                    <th>Amt.</th>
                                    <th>Reason</th>
                                    <th>Warning slip</th>
                                    <th>Reason</th>
                                    <th>GOOD</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="slip in employee.penaltySlips" :key="slip.date">
                                    <td>{{ formatDate(slip.date) }}</td>
                                    <td>{{ slip.siteName }}</td>
                                    <td>{{ slip.penaltySlip }}</td>
                                    <td>{{ slip.amount }}</td>
                                    <td>{{ slip.penaltyReason }}</td>
                                    <td>{{ slip.warningSlip }}</td>
                                    <td>{{ slip.warningReason }}</td>
                                    <td>{{ slip.good ? 'Yes' : 'No' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <h6 class="mt-4">Site Working Record</h6>
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Start Date</th>
                                    <th>Site name</th>
                                    <th>Dep</th>
                                    <th>Transfer to</th>
                                    <th colspan="2" class="text-center">Reliever report</th>
                                </tr>
                                <tr>
                                    <th colspan="4"></th>
                                    <th>Reliever date</th>
                                    <th>Site name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="record in employee.workingRecords" :key="record.id">
                                    <td>{{ formatDate(record.startDate) }}</td>
                                    <td>{{ record.siteName }}</td>
                                    <td>{{ record.department }}</td>
                                    <td>{{ record.transferTo }}</td>
                                    <td>{{ formatDate(record.relieverDate) }}</td>
                                    <td>{{ record.relieverSiteName }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- Reinstate and SIDDAR -->
                    <h6 class="mt-4">Reinstate and SIDDAR</h6>
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>DOJ</th>
                                    <th>Site</th>
                                    <th>Dept.</th>
                                    <th>Post</th>
                                    <th>SIDDAR type and Date</th>
                                    <th>Reason</th>
                                    <th>Left on</th>
                                    <th>Sidddar by</th>
                                    <th>Reinstate by</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="record in employee.reinstateRecords" :key="record.id">
                                    <td>{{ formatDate(record.doj) }}</td>
                                    <td>{{ record.site }}</td>
                                    <td>{{ record.department }}</td>
                                    <td>{{ record.post }}</td>
                                    <td>{{ record.siddarTypeAndDate }}</td>
                                    <td>{{ record.reason }}</td>
                                    <td>{{ formatDate(record.leftOn) }}</td>
                                    <td>{{ record.siddarBy }}</td>
                                    <td>{{ record.reinstateBy }}</td>
                                </tr>
                                <tr class="table-secondary">
                                    <td>{{ formatDate(employee.reInstantDate) }}</td>
                                    <td colspan="8">
                                        <strong>Re-instant Reason:</strong> {{ employee.reInstantReason }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- Complain Book -->
                    <h6 class="mt-4">Complain Book</h6>
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Offence category</th>
                                    <th>Sub category</th>
                                    <th>Warning/penalty/verbal</th>
                                    <th>Slip</th>
                                    <th>By</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="complaint in employee.complaints" :key="complaint.id">
                                    <td>{{ formatDate(complaint.date) }}</td>
                                    <td>{{ complaint.offenceCategory }}</td>
                                    <td>{{ complaint.subCategory }}</td>
                                    <td>{{ complaint.warningType }}</td>
                                    <td>
                                        <div class="form-check form-check-inline">
                                            <input type="checkbox" class="form-check-input" :checked="complaint.hasSlip"
                                                disabled>
                                            <label class="form-check-label">Yes/No</label>
                                        </div>
                                    </td>
                                    <td>{{ complaint.by }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <h6 class="mt-4">Uniform Record</h6>
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Items Issued and reissued</th>
                                    <th>Amt. total</th>
                                    <th>Deduction done</th>
                                    <th>Bad debt/amt. not recovered</th>
                                    <th>Reason</th>
                                    <th>Uniform return</th>
                                    <th>Original type and kept on</th>
                                    <th>Original doc returned on</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="record in employee.uniformRecords" :key="record.id">
                                    <td>{{ formatDate(record.date) }}</td>
                                    <td>{{ record.itemsIssued }}</td>
                                    <td>{{ record.amountTotal }}</td>
                                    <td>
                                        <div>Month-1: {{ record.deductions.month1 }}</div>
                                        <div>Month-2: {{ record.deductions.month2 }}</div>
                                        <div>Month-3: {{ record.deductions.month3 }}</div>
                                    </td>
                                    <td>Rs. {{ record.badDebt }}</td>
                                    <td>{{ record.reason }}</td>
                                    <td>{{ record.uniformReturn ? 'Yes' : 'No' }}</td>
                                    <td>{{ record.originalType }}</td>
                                    <td>{{ formatDate(record.docReturnDate) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- Promotion Record -->
                    <h6 class="mt-4">Promotion Record</h6>
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>DOJ</th>
                                    <th>Site name</th>
                                    <th>Dept.</th>
                                    <th>Post</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="record in employee.promotionRecords" :key="record.id">
                                    <td>{{ formatDate(record.doj) }}</td>
                                    <td>{{ record.siteName }}</td>
                                    <td>{{ record.department }}</td>
                                    <td>{{ record.post }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- Reinstate Information -->
                    <div class="mt-4 card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-3">
                                    <strong>Re-instate by:</strong> {{ employee.reinstateInfo.by }}
                                </div>
                                <div class="col-md-3">
                                    <strong>Date:</strong> {{ formatDate(employee.reinstateInfo.date) }}
                                </div>
                                <div class="col-md-3">
                                    <strong>Site name:</strong> {{ employee.reinstateInfo.siteName }}
                                </div>
                                <div class="col-md-3">
                                    <strong>Reason of reinstate:</strong> {{ employee.reinstateInfo.reason }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { useToast } from 'vue-toast-notification';
import { format } from 'date-fns';

const toast = useToast();

interface Document {
    type: string;
    status: 'pending' | 'uploaded' | 'verified';
    url?: string;
}

interface PenaltySlip {
    date: string;
    siteName: string;
    penaltySlip: string;
    amount: number;
    penaltyReason: string;
    warningSlip: string;
    warningReason: string;
    good: boolean;
}

interface Employee {
    id: number;
    photoUrl: string;
    name: string;
    doj: string;
    dob: string;
    siddarDate: string;
    reason: string;
    jobLeft: string;
    blacklisted: boolean;
    blacklistReason?: string;
    documents: {
        aadhar: boolean;
        pan: boolean;
        voter: boolean;
        passbook: boolean;
    };
    documentsList: Document[];
    penaltySlips: PenaltySlip[];
    workingRecords: WorkingRecord[];
    reinstateRecords: ReinstateRecord[];
    reInstantDate: string;
    reInstantReason: string;
    complaints: Complaint[];
    uniformRecords: UniformRecord[];
    promotionRecords: PromotionRecord[];
    reinstateInfo: ReinstateInfo;
}

interface WorkingRecord {
    id: number;
    startDate: string;
    siteName: string;
    department: string;
    transferTo: string;
    relieverDate: string;
    relieverSiteName: string;
}

interface ReinstateRecord {
    id: number;
    doj: string;
    site: string;
    department: string;
    post: string;
    siddarTypeAndDate: string;
    reason: string;
    leftOn: string;
    siddarBy: string;
    reinstateBy: string;
}

interface Complaint {
    id: number;
    date: string;
    offenceCategory: string;
    subCategory: string;
    warningType: string;
    hasSlip: boolean;
    by: string;
}

interface UniformDeductions {
    month1: number;
    month2: number;
    month3: number;
}

interface UniformRecord {
    id: number;
    date: string;
    itemsIssued: string;
    amountTotal: number;
    deductions: UniformDeductions;
    badDebt: number;
    reason: string;
    uniformReturn: boolean;
    originalType: string;
    docReturnDate: string;
}

interface PromotionRecord {
    id: number;
    doj: string;
    siteName: string;
    department: string;
    post: string;
}

interface ReinstateInfo {
    by: string;
    date: string;
    siteName: string;
    reason: string;
}

// Dummy employee data
const employee = ref<Employee>({
    id: 1,
    photoUrl: '',
    name: 'John Doe',
    doj: '2024-01-15',
    dob: '1990-05-20',
    siddarDate: '2024-02-01',
    reason: 'Regular employment',
    jobLeft: 'No',
    blacklisted: false,
    documents: {
        aadhar: true,
        pan: true,
        voter: false,
        passbook: true
    },
    documentsList: [
        { type: 'joiningForm', status: 'verified' },
        { type: 'declaration', status: 'verified' },
        { type: 'scans', status: 'verified' },
        { type: 'pvSent', status: 'verified' },
        { type: 'pvReturn', status: 'pending' },
        { type: 'esicDoc', status: 'uploaded' },
        { type: 'esicSigned', status: 'pending' },
        { type: 'resignationSlip', status: 'pending' }
    ],
    penaltySlips: [
        {
            date: '2024-02-15',
            siteName: 'Site A',
            penaltySlip: 'PS001',
            amount: 500,
            penaltyReason: 'Late arrival',
            warningSlip: 'WS001',
            warningReason: 'Repeated late arrivals',
            good: false
        }
    ],
    workingRecords: [
        {
            id: 1,
            startDate: '2024-01-15',
            siteName: 'Site 1',
            department: 'Security',
            transferTo: 'Site 2',
            relieverDate: '2024-02-01',
            relieverSiteName: 'Site 3'
        }
    ],
    reinstateRecords: [
        {
            id: 1,
            doj: '2024-01-15',
            site: 'Site A',
            department: 'Security',
            post: 'Guard',
            siddarTypeAndDate: 'Type A - 2024-02-01',
            reason: 'Performance',
            leftOn: '2024-01-30',
            siddarBy: 'John Manager',
            reinstateBy: 'Jane Supervisor'
        }
    ],
    reInstantDate: '2024-02-15',
    reInstantReason: 'Good past performance',
    complaints: [
        {
            id: 1,
            date: '2024-02-10',
            offenceCategory: 'Attendance',
            subCategory: 'Late arrival',
            warningType: 'Verbal',
            hasSlip: true,
            by: 'Site Supervisor'
        }
    ],
    uniformRecords: [
        {
            id: 1,
            date: '2024-01-15',
            itemsIssued: 'Uniform Set, Shoes',
            amountTotal: 2500,
            deductions: {
                month1: 800,
                month2: 800,
                month3: 900
            },
            badDebt: 0,
            reason: 'Regular issue',
            uniformReturn: false,
            originalType: 'Standard Issue',
            docReturnDate: '2024-04-15'
        }
    ],
    promotionRecords: [
        {
            id: 1,
            doj: '2024-01-15',
            siteName: 'Site A',
            department: 'Security',
            post: 'Senior Guard'
        }
    ],
    reinstateInfo: {
        by: 'John Manager',
        date: '2024-02-15',
        siteName: 'Site B',
        reason: 'Performance improvement'
    }
});

const formatDate = (date: string) => {
    if (!date) return '';
    return format(new Date(date), 'dd/MM/yyyy');
};

const getDocumentStatus = (docType: keyof typeof employee.value.documents) => {
    if (!employee.value.documents[docType]) return '';
    return '(Available for download)';
};

const getDocumentIcon = (status: Document['status']) => {
    const icons = {
        pending: 'bi bi-clock text-warning',
        uploaded: 'bi bi-check-circle text-info',
        verified: 'bi bi-check-circle-fill text-success'
    };
    return icons[status];
};

const downloadDocument = async (docType: string) => {
    try {
        // Implement actual download logic here
        toast.info(`Downloading ${docType} document...`);

        // Simulated API call
        await new Promise(resolve => setTimeout(resolve, 1000));

        toast.success(`${docType} document downloaded successfully`);
    } catch (error) {
        toast.error(`Failed to download ${docType} document`);
    }
};

// Function to handle document upload
const uploadDocument = async (docType: string, file: File) => {
    try {
        toast.info(`Uploading ${docType}...`);

        // Simulated API call
        await new Promise(resolve => setTimeout(resolve, 1500));

        // Update document status
        const docIndex = employee.value.documentsList.findIndex(doc => doc.type === docType);
        if (docIndex !== -1) {
            employee.value.documentsList[docIndex].status = 'uploaded';
        }

        toast.success(`${docType} uploaded successfully`);
    } catch (error) {
        toast.error(`Failed to upload ${docType}`);
    }
};

// Function to verify document
const verifyDocument = async (docType: string) => {
    try {
        toast.info(`Verifying ${docType}...`);

        // Simulated API call
        await new Promise(resolve => setTimeout(resolve, 1000));

        // Update document status
        const docIndex = employee.value.documentsList.findIndex(doc => doc.type === docType);
        if (docIndex !== -1) {
            employee.value.documentsList[docIndex].status = 'verified';
        }

        toast.success(`${docType} verified successfully`);
    } catch (error) {
        toast.error(`Failed to verify ${docType}`);
    }
};

// Function to add penalty slip
const addPenaltySlip = (penaltySlip: PenaltySlip) => {
    employee.value.penaltySlips.unshift(penaltySlip);
    toast.success('Penalty slip added successfully');
};

// Function to update employee details
const updateEmployeeDetails = async (updatedDetails: Partial<Employee>) => {
    try {
        toast.info('Updating employee details...');

        // Simulated API call
        await new Promise(resolve => setTimeout(resolve, 1000));

        // Update employee details
        employee.value = {
            ...employee.value,
            ...updatedDetails
        };

        toast.success('Employee details updated successfully');
    } catch (error) {
        toast.error('Failed to update employee details');
    }
};

onMounted(() => {
    // Initialize any required data or fetch from API
    // For now using dummy data initialized above
});
</script>

<style scoped>
.card {
    margin-bottom: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.employee-photo img {
    max-width: 200px;
    height: auto;
    border-radius: 8px;
}

.document-downloads .list-group-item {
    cursor: pointer;
    transition: all 0.2s;
}

.document-downloads .list-group-item:hover:not(:disabled) {
    background-color: #f8f9fa;
}

.document-downloads .list-group-item:disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

.table th {
    background-color: #f8f9fa;
    white-space: nowrap;
    font-size: 0.875rem;
}

.table td {
    vertical-align: middle;
    font-size: 0.875rem;
}

.bi {
    font-size: 1.1rem;
}

.text-warning {
    color: #ffc107;
}

.text-info {
    color: #17a2b8;
}

.text-success {
    color: #28a745;
}

.table-secondary {
    background-color: #e2e3e5;
}

.form-check-inline {
    margin: 0;
    justify-content: center;
}

@media (max-width: 768px) {
    .card-body {
        padding: 1rem;
    }

    .table {
        font-size: 0.8rem;
    }

    .employee-photo img {
        max-width: 150px;
    }
}
</style>