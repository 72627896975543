<script lang="js" setup>
import { ref, onMounted, Ref } from "vue";
import { fetchGetApi, fetchPostApi } from "@/util/api";

const allData = ref([]);
import Swal from 'sweetalert2';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { defineAsyncComponent } from 'vue';
import { Modal } from 'bootstrap';
import { is } from "date-fns/locale";
import Multiselect from 'vue-multiselect';


const modalCode = defineAsyncComponent(() => import("@/components/roles/modalCode.vue"));
const elementsPerPage = ref(25);
const currentPage = ref(1);
const filterQuery = ref("");
const toast = useToast();

const departmentName = ref('');
const departmentDescription = ref('');
onMounted(async () => {
    // getData()
});

function getData() {
    fetchGetApi('api/category/get/all').then((response) => {
        console.log(response.data);
        if (response && response.data && response.data.length > 0) {
            const sites = response.data
            console.log(sites);
            allData.value = sites;
            console.log(allData.value);
        } else {
            toast.error('Failed to fetch data')
        }
    });
}


function num_pages() {
    const query = filterQuery.value.toLowerCase();

    // Ensure allData.value is an array
    const data = Array.isArray(allData.value) ? allData.value : [];

    const filteredData = data.filter(kyt =>
        kyt.name.toLowerCase().includes(query) ||
        kyt.name.toLowerCase().includes(query)
    );

    return Math.ceil(filteredData.length / elementsPerPage.value);
}

function change_page(page) {
    currentPage.value = page;
}

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

function nextPage() {
    if (currentPage.value < num_pages()) {
        currentPage.value++;
    }
}

function confirmDelete(id) {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            deleteDepartment(id);
        }
    })
}
const isEditt = ref(false);
const dataa = ref({});

const modal = ref();
function showModal(isEdit = false, data = {}) {
    if (isEdit && data) {
        isEditt.value = true;
        dataa.value = data;
        departmentName.value = data.name;
        departmentDescription.value = data.description;
    } else { isEditt.value = false; }
    modal.value = new Modal(document.getElementById('grid-modal'));
    modal.value.show();
}
function hideModal() {
    isEditt.value = false;
    dataa.value = {};
    departmentName.value = '';
    departmentDescription.value = '';

    modal.value.hide();
}
function deleteDepartment(id) {
    fetchGetApi('api/delete/category/' + id).then((response) => {
        console.log(response);
        toast.success(response.data['message']);
        getData();
    });
}
function formatDate(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
}

function updateDepartment() {

    if (!departmentName.value) {
        toast.error('Department Name is required');
        return;
    }
    if (!departmentDescription.value) {
        toast.error('Department Description is required');
        return;
    }
    const data = {
        name: departmentName.value,
        description: departmentDescription.value
    }
    console.log(dataa.value);
    var url = isEditt.value ? 'api/category/edit/' + dataa.value._id : 'api/category/create';
    console.log(url);
    // return;
    fetchPostApi(url, data).then((response) => {
        console.log(response);
        toast.success(response.data['message']);
        hideModal();
        getData();
    });
}

const reports = ref([{
    date: '2023-10-01',
    reportType: 'First',
    reportedBy: 'John Doe',
    area: 'Area 1',
    projectName: 'Project A',
    serviceDept: 'Dept 1',
    modeOfService: 'Online',
    agencyName: 'Agency X',
    totalManpower: 10,
    workingHour: '8 hours',
    minimumWages: '$15/hour',
    salary: '$120/day',
    food: 'Provided',
    stay: 'Provided',
    esicPf: 'Yes',
    weeklyOff: 'Sunday',
    agreementStartExpiry: '2023-01-01 to 2023-12-31',
    specialMention: 'None',
},]);

</script>
<template>
    <Breadcrumbs main="Apps" title="SOC analysis" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row card card-body">
                <div class="table-responsive product-table custom-scrollbar">
                    <form class="no-footer">
                        <div class="mb-3 row ">
                                <div class="col-xs-3 col-sm-auto">
                                    <label for="site-name-search" class="col-xs-12 col-sm-auto col-form-label">Site
                                        Name:</label>
                                    <input id="site-name-search" type="text" class="form-control" name="siteName"
                                        v-model="siteNameFilter">
                                </div>
                                <div class="col-xs-3 col-sm-auto">
                                    <label for="service-search"
                                        class="col-xs-12 col-sm-auto col-form-label">Service:</label>
                                    <input id="service-search" type="text" class="form-control" name="service"
                                        v-model="serviceFilter">
                                </div>
                                <div class="col-xs-3 col-sm-auto">
                                    <label for="competitor-name-search"
                                        class="col-xs-12 col-sm-auto col-form-label">Competitor Name:</label>
                                    <input id="competitor-name-search" type="text" class="form-control"
                                        name="competitorName" v-model="competitorNameFilter">
                                </div>

                            <div class="col-xs-3 ms-3 mt-3 col-sm-auto d-flex align-items-center">
                                <button class="btn btn-primary" @click="search">
                                    <i class="fas fa-search"></i>
                                </button>
                            </div>
                        </div>
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">SL No</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Report Type</th>
                                    <th scope="col">Reported By</th>
                                    <th scope="col">Area</th>
                                    <th scope="col">Project Name</th>
                                    <th scope="col">Service (Dept)</th>
                                    <th scope="col">Mode of Service</th>
                                    <th scope="col">Agency Name</th>
                                    <th scope="col">Total Manpower</th>
                                    <th scope="col">Working Hour</th>
                                    <th scope="col">Minimum Wages</th>
                                    <th scope="col">Salary</th>
                                    <th scope="col">Food</th>
                                    <th scope="col">Stay</th>
                                    <th scope="col">ESIC/PF</th>
                                    <th scope="col">Weekly Off</th>
                                    <th scope="col">Agreement Start and Expiry</th>
                                    <th scope="col">Special Mention</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(report, index) in reports" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ report.date }}</td>
                                    <td>{{ report.reportType }}</td>
                                    <td>{{ report.reportedBy }}</td>
                                    <td>{{ report.area }}</td>
                                    <td>{{ report.projectName }}</td>
                                    <td>{{ report.serviceDept }}</td>
                                    <td>{{ report.modeOfService }}</td>
                                    <td>{{ report.agencyName }}</td>
                                    <td>{{ report.totalManpower }}</td>
                                    <td>{{ report.workingHour }}</td>
                                    <td>{{ report.minimumWages }}</td>
                                    <td>{{ report.salary }}</td>
                                    <td>{{ report.food }}</td>
                                    <td>{{ report.stay }}</td>
                                    <td>{{ report.esicPf }}</td>
                                    <td>{{ report.weeklyOff }}</td>
                                    <td>{{ report.agreementStartExpiry }}</td>
                                    <td>{{ report.specialMention }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>

                <ul class="pagination py-2 justify-content-end pagination-primary">
                    <li class="page-item" @click="previousPage">
                        <a class="page-link">Previous</a>
                    </li>
                    <li class="page-item" v-for="i in num_pages()" :key="i" :class="[i == currentPage ? 'active' : '']"
                        @click="change_page(i)">
                        <a class="page-link">{{ i }}</a>
                    </li>
                    <li class="page-item" @click="nextPage">
                        <a class="page-link">Next</a>
                    </li>
                </ul>
            </div>




        </div>
    </div>

    <div class="modal fade" id="grid-modal" tabindex="-1" role="dialog" aria-labelledby="grid-modal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Modal title</h5>
                    <button class="btn-close" type="button" @click="hideModal()" aria-label="Close"></button>
                </div>
                <div class="modal-body grid-showcase">
                    <div class="container-fluid bd-example-row">
                        <div class="row">
                            <!-- Form for Department Name and Description -->
                            <form class="col-md-12">
                                <div class="mb-3">
                                    <label for="department-name" class="form-label">Department Name</label>
                                    <input type="text" class="form-control" id="department-name"
                                        v-model="departmentName">
                                </div>
                                <div class="mb-3">
                                    <label for="department-description" class="form-label">Department
                                        Description</label>
                                    <textarea class="form-control" id="department-description" rows="3"
                                        v-model="departmentDescription"></textarea>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">Close</button>
                    <button class="btn btn-primary" @click="updateDepartment()" type="button">Save</button>
                </div>
            </div>
        </div>
    </div>

</template>