<template>
    <div class="container-fluid">
        <div class="card">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h5 class="mb-0">PV Details</h5>
                <button class="btn btn-dark" @click="downloadExcel">
                    Download excel
                </button>
            </div>
            <div class="card-body">
                <!-- Search Filters -->
                <div class="row mb-4">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Search by date</label>
                            <div class="d-flex align-items-center">
                                <input type="date" class="form-control" v-model="startDate">
                                <span class="mx-2">to</span>
                                <input type="date" class="form-control" v-model="endDate">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Search by emp. Name</label>
                            <input type="text" class="form-control" v-model="searchName">
                        </div>
                    </div>
                </div>

                <!-- Table -->
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th rowspan="2">SL NO.</th>
                                <th rowspan="2">Date of joining</th>
                                <th rowspan="2">NAME OF EMPLOYEES</th>
                                <th rowspan="2">DESIGNATION</th>
                                <th colspan="6" class="text-center">ADDRESS</th>
                            </tr>
                            <tr>
                                <th>VILLAGE</th>
                                <th>PO</th>
                                <th>PS</th>
                                <th>DISTRICT</th>
                                <th>STATE</th>
                                <th>PIN</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(employee, index) in filteredEmployees" :key="index">
                                <td>{{ index + 1 }}</td>
                                <td>{{ formatDate(employee.joiningDate) }}</td>
                                <td>{{ employee.name }}</td>
                                <td>{{ employee.designation }}</td>
                                <td>{{ employee.village }}</td>
                                <td>{{ employee.po }}</td>
                                <td>{{ employee.ps }}</td>
                                <td>{{ employee.district }}</td>
                                <td>{{ employee.state }}</td>
                                <td>{{ employee.pin }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { format } from 'date-fns';
import * as XLSX from 'xlsx';

// State
const startDate = ref('');
const endDate = ref('');
const searchName = ref('');

// Sample data - replace with your actual data source
const employees = ref([
    {
        joiningDate: new Date(),
        name: 'John Doe',
        designation: 'SECURITY GUARD',
        village: 'Sample Village',
        po: 'Sample PO',
        ps: 'Sample PS',
        district: 'Sample District',
        state: 'Sample State',
        pin: '123456'
    }
    // Add more employee records as needed
]);

// Computed
const filteredEmployees = computed(() => {
    return employees.value.filter(emp => {
        const matchesDate = !startDate.value || !endDate.value || 
            (emp.joiningDate >= new Date(startDate.value) && 
             emp.joiningDate <= new Date(endDate.value));
        
        const matchesName = !searchName.value || 
            emp.name.toLowerCase().includes(searchName.value.toLowerCase());
        
        return matchesDate && matchesName;
    });
});

// Methods
const formatDate = (date: Date) => {
    return format(date, 'dd-MM-yyyy');
};

const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredEmployees.value);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'PV Details');
    XLSX.writeFile(workbook, 'pv_details.xlsx');
};
</script>

<style scoped>
.table th {
    background-color: #f8f9fa;
    vertical-align: middle;
    text-align: center;
}

.table td {
    vertical-align: middle;
}

.form-group {
    margin-bottom: 1rem;
}

.table-responsive {
    margin-top: 1rem;
}
</style>