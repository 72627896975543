<script lang="js" setup>
import { ref, onMounted, Ref } from "vue";
import { fetchGetApi, fetchPostApi } from "@/util/api";

const allData = ref([]);
import Swal from 'sweetalert2';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { defineAsyncComponent } from 'vue';
import { Modal } from 'bootstrap';
import { is } from "date-fns/locale";
import Multiselect from 'vue-multiselect';


const modalCode = defineAsyncComponent(() => import("@/components/roles/modalCode.vue"));
const elementsPerPage = ref(25);
const currentPage = ref(1);
const filterQuery = ref("");
const toast = useToast();

const departmentName = ref('');
const departmentDescription = ref('');
onMounted(async () => {
    // getData()
});

function getData() {
    fetchGetApi('api/category/get/all').then((response) => {
        console.log(response.data);
        if (response && response.data && response.data.length > 0) {
            const sites = response.data
            console.log(sites);
            allData.value = sites;
            console.log(allData.value);
        } else {
            toast.error('Failed to fetch data')
        }
    });
}


function num_pages() {
    const query = filterQuery.value.toLowerCase();

    // Ensure allData.value is an array
    const data = Array.isArray(allData.value) ? allData.value : [];

    const filteredData = data.filter(kyt =>
        kyt.name.toLowerCase().includes(query) ||
        kyt.name.toLowerCase().includes(query)
    );

    return Math.ceil(filteredData.length / elementsPerPage.value);
}

function change_page(page) {
    currentPage.value = page;
}

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

function nextPage() {
    if (currentPage.value < num_pages()) {
        currentPage.value++;
    }
}

function confirmDelete(id) {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            deleteDepartment(id);
        }
    })
}
const isEditt = ref(false);
const dataa = ref({});

const modal = ref();
function showModal(isEdit = false, data = {}) {
    if (isEdit && data) {
        isEditt.value = true;
        dataa.value = data;
        departmentName.value = data.name;
        departmentDescription.value = data.description;
    } else { isEditt.value = false; }
    modal.value = new Modal(document.getElementById('grid-modal'));
    modal.value.show();
}
function hideModal() {
    isEditt.value = false;
    dataa.value = {};
    departmentName.value = '';
    departmentDescription.value = '';

    modal.value.hide();
}
function deleteDepartment(id) {
    fetchGetApi('api/delete/category/' + id).then((response) => {
        console.log(response);
        toast.success(response.data['message']);
        getData();
    });
}
function formatDate(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
}

function updateDepartment() {

    if (!departmentName.value) {
        toast.error('Department Name is required');
        return;
    }
    if (!departmentDescription.value) {
        toast.error('Department Description is required');
        return;
    }
    const data = {
        name: departmentName.value,
        description: departmentDescription.value
    }
    console.log(dataa.value);
    var url = isEditt.value ? 'api/category/edit/' + dataa.value._id : 'api/category/create';
    console.log(url);
    // return;
    fetchPostApi(url, data).then((response) => {
        console.log(response);
        toast.success(response.data['message']);
        hideModal();
        getData();
    });
}
const reports = ref([{
    previousMonthProjection: 'January (Site A)',
    percentageAtProjection: '60%',
    currentPercentage: '70%',
    projectType: 'Type 1',
    previousProjectType: 'Type 2',
    newProjectFound: 'Project X',
    projectsConfirmed: 'Project Y',
    manpower: '50',
    revenue: '$5000',
},
{
    previousMonthProjection: 'February (Site B)',
    percentageAtProjection: '55%',
    currentPercentage: '65%',
    projectType: 'Type 3',
    previousProjectType: 'Type 1',
    newProjectFound: 'Project Z',
    projectsConfirmed: 'Project W',
    manpower: '40',
    revenue: '$4000',
},
{
    previousMonthProjection: 'March (Site C)',
    percentageAtProjection: '75%',
    currentPercentage: '80%',
    projectType: 'Type 2',
    previousProjectType: 'Type 3',
    newProjectFound: 'Project V',
    projectsConfirmed: 'Project U',
    manpower: '60',
    revenue: '$6000',
},
{
    previousMonthProjection: 'April (Site D)',
    percentageAtProjection: '50%',
    currentPercentage: '55%',
    projectType: 'Type 4',
    previousProjectType: 'Type 2',
    newProjectFound: 'Project T',
    projectsConfirmed: 'Project S',
    manpower: '30',
    revenue: '$3000',
}])
const selectedMonth = ref('');

</script>
<template>
    <Breadcrumbs main="Apps" title="JUST analysis" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row card card-body">
                <div class="table-responsive product-table custom-scrollbar">
                    <form class="no-footer">
                        <div class="mb-3 row ">
                            <div class="col-xs-3 col-sm-auto">
                                <label for="month-search" class="col-xs-12 col-sm-auto col-form-label">Search by
                                    Month:</label>
                                <select id="month-search" class="form-control" v-model="selectedMonth">
                                    <option value="">Select Month</option>
                                    <option value="January">January</option>
                                    <option value="February">February</option>
                                    <option value="March">March</option>
                                    <option value="April">April</option>
                                    <option value="May">May</option>
                                    <option value="June">June</option>
                                    <option value="July">July</option>
                                    <option value="August">August</option>
                                    <option value="September">September</option>
                                    <option value="October">October</option>
                                    <option value="November">November</option>
                                    <option value="December">December</option>
                                </select>
                            </div>

                            <div class="col-xs-3 ms-3 mt-3 col-sm-auto d-flex align-items-center">
                                <button class="btn btn-primary" @click="search">
                                    <i class="fas fa-search"></i>
                                </button>
                            </div>
                        </div>
                        <table class="display table-striped table-hover table-bordered table" id="basic-1">
                            <thead>

                                <tr>
                                    <th class="sorting_asc" scope="col" style="width: 50px;">SL No</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Previous month (month name)
                                        projection (site name)</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Percentage at time of projection
                                    </th>
                                    <th class="sorting_asc text-nowrap" scope="col">Current percentage</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Project type</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Previous project type</th>
                                    <th class="sorting_asc text-nowrap" scope="col">New project found</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Projects confirmed</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Manpower</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Revenue</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(report, index) in reports" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ report.previousMonthProjection }}</td>
                                    <td>{{ report.percentageAtProjection }}</td>
                                    <td>{{ report.currentPercentage }}</td>
                                    <td>{{ report.projectType }}</td>
                                    <td>{{ report.previousProjectType }}</td>
                                    <td>{{ report.newProjectFound }}</td>
                                    <td>{{ report.projectsConfirmed }}</td>
                                    <td>{{ report.manpower }}</td>
                                    <td>{{ report.revenue }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>

                <ul class="pagination py-2 justify-content-end pagination-primary">
                    <li class="page-item" @click="previousPage">
                        <a class="page-link">Previous</a>
                    </li>
                    <li class="page-item" v-for="i in num_pages()" :key="i" :class="[i == currentPage ? 'active' : '']"
                        @click="change_page(i)">
                        <a class="page-link">{{ i }}</a>
                    </li>
                    <li class="page-item" @click="nextPage">
                        <a class="page-link">Next</a>
                    </li>
                </ul>
            </div>




        </div>
    </div>

    <div class="modal fade" id="grid-modal" tabindex="-1" role="dialog" aria-labelledby="grid-modal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Modal title</h5>
                    <button class="btn-close" type="button" @click="hideModal()" aria-label="Close"></button>
                </div>
                <div class="modal-body grid-showcase">
                    <div class="container-fluid bd-example-row">
                        <div class="row">
                            <!-- Form for Department Name and Description -->
                            <form class="col-md-12">
                                <div class="mb-3">
                                    <label for="department-name" class="form-label">Department Name</label>
                                    <input type="text" class="form-control" id="department-name"
                                        v-model="departmentName">
                                </div>
                                <div class="mb-3">
                                    <label for="department-description" class="form-label">Department
                                        Description</label>
                                    <textarea class="form-control" id="department-description" rows="3"
                                        v-model="departmentDescription"></textarea>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">Close</button>
                    <button class="btn btn-primary" @click="updateDepartment()" type="button">Save</button>
                </div>
            </div>
        </div>
    </div>

</template>