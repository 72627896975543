<template>
    <div class="col-sm-12">
        <div class="card">
            <div class="card-body">
                <div class="form theme-form">

                    <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog"
                        aria-labelledby="myLargeModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h4 class="modal-title" id="myLargeModalLabel">View </h4>
                                    <button class="btn-close" type="button" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="mb-3">
                                                <label>Date</label>
                                                <input class="form-control" type="text" :value="date" disabled />
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="mb-3">
                                                <label>Name</label>
                                                <input class="form-control" type="text" :value="name" disabled />
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="mb-3">
                                                <label>Site</label>
                                                <input class="form-control" type="text" :value="site" disabled />
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="mb-3">
                                                <label>Designation</label>
                                                <input class="form-control" type="text" :value="designation" disabled />
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="mb-3">
                                                <label>Location</label>
                                                <input class="form-control" type="text" :value="location" disabled />
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="mb-3">
                                                <label>PF/ESIC</label>
                                                <input class="form-control" type="text" :value="pfEsic" disabled />
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="mb-3">
                                                <label>Stay</label>
                                                <input class="form-control" type="text" :value="stay" disabled />
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="mb-3">
                                                <label>Weekly Off</label>
                                                <input class="form-control" type="text" :value="weeklyOff" disabled />
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="mb-3">
                                                <label>Working Hours</label>
                                                <input class="form-control" type="text" :value="workingHours"
                                                    disabled />
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="mb-3">
                                                <label>Registration Amount</label>
                                                <input class="form-control" type="text" :value="registrationAmount"
                                                    disabled />
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="mb-3">
                                                <label>Dress Issue</label>
                                                <input class="form-control" type="text" :value="dressIssue" disabled />
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="mb-3">
                                                <label>Priority</label>
                                                <input class="form-control" type="text" :value="priority" disabled />
                                            </div>
                                        </div>


                                        <div class="col-6">
                                            <div class="mb-3">
                                                <label>Candidate Signature</label>
                                                <img src="https://repository-images.githubusercontent.com/8805592/85279ffa-7f4a-4880-8e41-59e8032b0f71"
                                                    alt="Candidate Signature" style="width: 200px; height: auto;" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-6">
                            <div class="mb-3">
                                <label>Date</label>
                                <input class="form-control" type="text" placeholder="12/12/2024" disabled />
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="mb-3">
                                <label>Name</label>
                                <input class="form-control" type="text" placeholder="Abu Tahir" disabled />
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="mb-3">
                                <label>Site</label>
                                <br />
                                <input type="radio" id="esicPfSite" name="siteType" value="yes" v-model="siteType" />
                                <label for="esicPfSite" class="m-10">ESIC/PF Sites</label>
                                <input type="radio" id="nonEsicPfSite" name="siteType" value="no" v-model="siteType" />
                                <label for="nonEsicPfSite">Non ESIC/PF Sites</label>
                            </div>
                        </div>
                        <div class="col-sm-6" v-if="siteType === 'yes'">
                            <div class="mb-3">
                                <label>ESIC/PF Sites</label>
                                <select class="form-select">
                                    <option>Site 1</option>
                                    <option>Site 2</option>
                                    <option>Site 3</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-6" v-if="siteType === 'no'">
                            <div class="mb-3">
                                <label>Sites</label>
                                <select class="form-select">
                                    <option>Site 1</option>
                                    <option>Site 2</option>
                                    <option>Site 3</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="mb-3">
                                <label>Designation</label>
                                <select class="form-select">
                                    <option>SG</option>
                                    <option>SK</option>
                                    <option>Other</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="mb-3">
                                <label>Location</label>
                                <input class="form-control" type="text" placeholder="Guwahati" disabled />
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="mb-3">
                                <label>PF/ESIC</label>
                                <input class="form-control" type="text" placeholder="PF/ESIC" disabled />
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="mb-3">
                                <label>Stay</label>
                                <input class="form-control" type="text" placeholder="Stay" disabled />
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="mb-3">
                                <label>Weekly Off</label>
                                <input class="form-control" type="text" placeholder="Weekly Off" disabled />
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="mb-3">
                                <label>Working Hours</label>
                                <input class="form-control" type="text" placeholder="16" disabled />
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="mb-3">
                                <label>Registration Amount</label>
                                <input class="form-control" type="text" placeholder="1000" />
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="mb-3">
                                <label>Dress Issue</label>
                                <input class="form-control" type="text" placeholder="Dress Issue" />
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="mb-3">
                                <label>Candidate Signature</label>
                                <drop-zone />
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="mb-3">
                                <label>Priority</label>
                                <br />
                                <input type="radio" id="priority-yes" name="priority" value="yes" />
                                <label for="priority-yes" class="m-10">Yes</label>
                                <input type="radio" id="priority-no" name="priority" value="no" />
                                <label for="priority-no">No</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="text-end">
                                <button class="btn btn-primary" type="button" data-bs-toggle="modal"
                                    data-bs-target=".bd-example-modal-lg">View </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script lang="ts" setup>
import { ref, defineAsyncComponent } from 'vue';

const dropZone = defineAsyncComponent(() => import('@/components/common/dropZone.vue'));

const siteType = ref<string | null>(null);

const date = ref('12/12/2024');
const name = ref('Abu Tahir');
const site = ref('ESIC/PF Sites');
const designation = ref('SG');
const location = ref('Guwahati');
const pfEsic = ref('PF/ESIC');
const stay = ref('Stay');
const weeklyOff = ref('Weekly Off');
const workingHours = ref('16');
const registrationAmount = ref('1000');
const dressIssue = ref('Dress Issue');
const priority = ref('Yes');
</script>