<script lang="js" setup>
import { ref, onMounted } from "vue"
import Swal from 'sweetalert2'
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { defineAsyncComponent } from 'vue';
import { fetchGetApi, fetchPostApi } from '@/util/api';

const modalCode = defineAsyncComponent(() => import("@/components/roles/modalCode.vue"))
const elementsPerPage = ref(25)
const currentPage = ref(1)
const filterQuery = ref("")
const allData = ref([])
const toast = useToast()
const opsName = ref('')

onMounted(async () => {

    fetchPostApi('api/kyt/get/kyts').then((response) => {
        console.log(response);
        if (response && response.data && response.data.length > 0) {
            const kyts = response.data
            console.log(kyts);
            allData.value = kyts;
        } else {
            toast.error('Failed to fetch data')
        }
    })

})
function formatDate(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
}
function get_rows() {
    const start = (currentPage.value - 1) * elementsPerPage.value
    const end = start + elementsPerPage.value
    const query = filterQuery.value.toLowerCase()
    const filteredData = allData.value.filter(kyt =>
        kyt.siteName.toLowerCase().includes(query) ||
        kyt.siteAddress.toLowerCase().includes(query)
    )
    return filteredData.slice(start, end)
}



function num_pages() {
    const query = filterQuery.value.toLowerCase()
    const filteredData = allData.value.filter(kyt =>
        kyt.siteName.toLowerCase().includes(query) ||
        kyt.siteAddress.toLowerCase().includes(query)
    )
    return Math.ceil(filteredData.length / elementsPerPage.value)
}

function change_page(page) {
    currentPage.value = page
}

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

function nextPage() {
    if (currentPage.value < num_pages()) {
        currentPage.value++;
    }
}
function openMap(lat, long) {
    if (typeof window !== 'undefined' && window.open) {
        window.open(`https://www.google.com/maps?q=${lat},${long}`);
    } else {
        console.error('Window object is not available.');
    }
}

</script>
<template>
    <Breadcrumbs main="Apps" title="Operation KYTs" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row card card-body">
                <div class="table-responsive product-table custom-scrollbar">
                    <form class="no-footer">
                        <div class="mb-3 row ">
                            <div class="col-xs-3 col-sm-auto">
                                <label class="col-xs-12 col-sm-auto col-form-label">Date:</label>
                                <div class="d-flex align-items-center">
                                    <input type="date" class="form-control" v-model="startDate" style="width: 150px;">
                                </div>
                            </div>
                            <div class="col-xs-3 col-sm-auto">
                                <label for="table-complete-search"
                                    class="col-xs-12 col-sm-auto col-form-label">Site name:</label>
                                <input id="table-complete-search" type="text" class="form-control" name="searchTerm"
                                    v-model="filterQuery"style="width: 150px;">
                            </div>
                            <div class="col-2">
                                <label for="table-complete-entries"
                                    class="col-3 col-sm-auto col-form-label">Operation Manager:</label>
                                <select id="table-complete-entries" class="form-select" name="entries"
                                    v-model="opsName">
                                    <option value="Kawsar">Kawsar</option>
                                    <option value="Tamanna">Tamanna</option>
                                </select>
                            </div>
                            <div class="col-2">
                                <label for="table-complete-entries"
                                    class="col-3 col-sm-auto col-form-label">Show:</label>
                                <select id="table-complete-entries" class="form-select" name="entries"
                                    v-model="elementsPerPage">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                        <table class="display table-striped table-hover table-bordered table" id="basic-1">
                            <thead>
                                <tr>
                                    <th class="sorting_asc text-nowrap" scope="col" style="width: 50px;">SL</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Site Name</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Address</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Operation manager</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Work duration</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Travel duration</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Type of visit</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Date-Time</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Check work</th>
                                    <th class="sorting_asc text-nowrap text-center" scope="col">Is Completed</th>
                                    <th class="sorting_asc text-nowrap" scope="col" style="text-align: center;">Priority</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody v-if="!get_rows().length">
                                <tr class="odd">
                                    <td valign="top" colspan="8" class="dataTables_empty">No matching records found</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr class="odd" v-for="(row, index) in get_rows()" :key="index">
                                    <td class="sorting_1" style="width: 10px;">{{ index + 1 }}</td>
                                    <td class="sorting_1">
                                        <h6>{{ row.siteName }}</h6>
                                    </td>
                                    <td class="sorting_1 text-nowrap">
                                        <a @click="openMap(row.siteLat, row.siteLong)" href="#">{{ row.siteAddress
                                            }}</a>

                                    </td>
                                    <td class="sorting_1">{{ row.employeeDetails?.name ?? '' }}</td>
                                    <td class="sorting_1">{{ row.workDuration ?? '' }}</td>
                                    <td class="sorting_1">{{ row.travelDuration ?? '' }}</td>
                                    <td class="sorting_1">{{ row.typeOfVisit ?? '' }}</td>
                                    
                                    <td class="sorting_1">{{ formatDate(row.date) + ' - ' + row.time }}</td>
                                    <td class=" sorting_1">View</td>
                                    <td class="sorting_1 text-center">
                                        <div type="button" class="btn" :class="{
                                            'btn-success': row.isCompleted,
                                            'btn-danger': !row.isCompleted,
                                        }" style="scale: 0.7;cursor: auto;">
                                            {{ row.isCompleted == true ? 'Completed' : 'Not Completed' }}
                                        </div>
                                        <!-- <button class="btn btn-pill btn-success btn-air-success"
                                v-if="row.isCompleted">Completed</button>
                            <button class="btn btn-pill btn-info btn-air-info" v-else>Not Completed</button> -->
                                    </td>
                                    <td class="sorting_1" style="text-align: center;">
                                        <div class="btn btn-danger-gradien" style="scale: 0.7;cursor: auto;"
                                            v-if="row.isPriority">
                                            Priority</div>
                                        <div class="btn btn-success-gradien" style="scale: 0.7;cursor: auto;" v-else>
                                            Non-Priority
                                        </div>
                                    </td>
                                    <td>
                                        <!-- <a class="text-primary me-2" data-bs-toggle="modal" data-bs-target="#grid-modal"
                                title="Edit Role" style="cursor: pointer; font-size:20px;">
                                <i class="fas fa-eye"></i>
                            </a> -->

                                        <router-link :to="`view/${row._id}`" class="text-primary me-2" title="View"
                                            target="_blank" style="cursor: pointer;  font-size:20px;">
                                            <i class="fas fa-eye"></i>
                                        </router-link>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <ul class="pagination py-2 justify-content-end pagination-primary">
                            <li class="page-item" @click="previousPage">
                                <a class="page-link">Previous</a>
                            </li>
                            <li class="page-item" v-for="i in num_pages()" :key="i"
                                :class="[i == currentPage ? 'active' : '']" @click="change_page(i)">
                                <a class="page-link">{{ i }}</a>
                            </li>
                            <li class="page-item" @click="nextPage">
                                <a class="page-link">Next</a>
                            </li>
                        </ul>
                    </form>
                    <modalCode />
                </div>
            </div>
        </div>
    </div>

</template>
