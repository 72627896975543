<script lang="js" setup>
import { ref, onMounted, Ref } from "vue";
import { fetchGetApi } from "@/util/api";
import jsonToExcel from '@/util/makeExcel';
import jsonToPdf from '@/util/makeExcel';

const allData = ref([]);
import Swal from 'sweetalert2';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { defineAsyncComponent } from 'vue';

const modalCode = defineAsyncComponent(() => import("@/components/roles/modalCode.vue"));
const elementsPerPage = ref(25);
const currentPage = ref(1);
const filterQuery = ref("");
const toast = useToast();

onMounted(async () => {
    fetchGetApi('api/cdw/getAllCdw').then((response) => {
        console.log(response.data);
        if (response && response.data && response.data.length > 0) {
            const sites = response.data
            console.log(sites);
            allData.value = sites;
            console.log(allData.value);
        } else {
            toast.error('Failed to fetch data')
        }
    });
});

function downloadExcel() {
    jsonToExcel(kypList.value, 'kyp.xlsx');
    // jsonToPdf(kypList.value, 'kyp.pdf');
}

function num_pages() {
    const query = filterQuery.value.toLowerCase();

    // Ensure allData.value is an array
    const data = Array.isArray(allData.value) ? allData.value : [];

    const filteredData = data.filter(kyt =>
        kyt.siteId.toLowerCase().includes(query) ||
        kyt.siteId.toLowerCase().includes(query)
    );

    return Math.ceil(filteredData.length / elementsPerPage.value);
}

function change_page(page) {
    currentPage.value = page;
}

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

function nextPage() {
    if (currentPage.value < num_pages()) {
        currentPage.value++;
    }
}

const dummyData = ref([
    {
        id: 1,
        siteName: "Site A",
        department: "HR",
        employee: "John Doe",
        designation: "Manager",
        gender: "Male",
        daysWork: 200,
        typeOfLeaving: "Resignation",
        type: "Permanent",
        vacantType: "Full-time",
        reason: "Personal",
        dressReturn: "Yes",
        deadline: "2023-12-31",
        status: "Completed"
    },
    {
        id: 2,
        siteName: "Site B",
        department: "Finance",
        employee: "Jane Smith",
        designation: "Analyst",
        gender: "Female",
        daysWork: 150,
        typeOfLeaving: "Termination",
        type: "Contract",
        vacantType: "Part-time",
        reason: "Performance",
        dressReturn: "No",
        deadline: "2023-11-30",
        status: "Pending"
    }
]);
</script>

<template>
    <Breadcrumbs main="Apps" title="SIDDAR" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row card card-body">
                <div class="table-responsive product-table custom-scrollbar">
                    <form class="no-footer">
                        <div class="mb-3 row justify-content-between">
                            <div class="col-xs-3 col-sm-auto">
                                <label for="table-complete-search"
                                    class="col-xs-12 col-sm-auto col-form-label">Search:</label>
                                <input id="table-complete-search" type="text" class="form-control" name="searchTerm"
                                    v-model="filterQuery">
                            </div>
                            <div class="col-xs-3 col-sm-auto">
                                <button type="button" class="btn btn-primary" @click="downloadExcel">Download Excel</button>
                            </div>
                        </div>
                        <table class="display table-striped table-hover table-bordered table" id="basic-1">
                            <thead>
                                <tr>
                                    <th class="sorting_asc" scope="col" style="width: 50px;">SL</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Site Name</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Department</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Employee</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Designation</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Gender</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Days Work</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Type of Leaving</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Type</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Vacant Type</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Reason</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Dress Return</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Deadline</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in dummyData" :key="item.id">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.siteName }}</td>
                                    <td>{{ item.department }}</td>
                                    <td>{{ item.employee }}</td>
                                    <td>{{ item.designation }}</td>
                                    <td>{{ item.gender }}</td>
                                    <td>{{ item.daysWork }}</td>
                                    <td>{{ item.typeOfLeaving }}</td>
                                    <td>{{ item.type }}</td>
                                    <td>{{ item.vacantType }}</td>
                                    <td>{{ item.reason }}</td>
                                    <td>{{ item.dressReturn }}</td>
                                    <td>{{ item.deadline }}</td>
                                    <td>{{ item.status }}</td>
                                </tr>
                            </tbody>
                        </table>

                        <ul class="pagination py-2 justify-content-end pagination-primary">
                            <li class="page-item" @click="previousPage">
                                <a class="page-link">Previous</a>
                            </li>
                            <li class="page-item" v-for="i in num_pages()" :key="i"
                                :class="[i == currentPage ? 'active' : '']" @click="change_page(i)">
                                <a class="page-link">{{ i }}</a>
                            </li>
                            <li class="page-item" @click="nextPage">
                                <a class="page-link">Next</a>
                            </li>
                        </ul>
                    </form>
                    <modalCode />
                </div>
            </div>
        </div>
    </div>
</template>