<template>
    <Breadcrumbs main="Reports" title="Return Item Report" />
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <!-- Filters -->
                <div class="row mb-4">
                    <div class="col-md-3">
                        <div class="form-group">
                            <label>Search by Site</label>
                            <select class="form-select" v-model="filters.site">
                                <option value="">All Sites</option>
                                <option v-for="site in sites" :key="site" :value="site">{{ site }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Search by Return Date Range</label>
                            <div class="d-flex gap-2">
                                <input type="date" class="form-control" v-model="filters.dateFrom">
                                <span class="align-self-center">to</span>
                                <input type="date" class="form-control" v-model="filters.dateTo">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label>Search by Name</label>
                            <input type="text" class="form-control" v-model="filters.name" placeholder="Enter name">
                        </div>
                    </div>
                    <div class="col-md-3 mt-3">
                        <div class="form-group">
                            <label>Search by Designation</label>
                            <select class="form-select" v-model="filters.designation">
                                <option value="">All Designations</option>
                                <option v-for="designation in designations" :key="designation" :value="designation">
                                    {{ designation }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <!-- Table -->
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Date of Issue</th>
                                <th>Date of Return</th>
                                <th>Name</th>
                                <th>Designation</th>
                                <th>Site Name</th>
                                <th>Return Items</th>
                                <th>Nearest laundry slot</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in filteredItems" :key="index">
                                <td>{{ item.issueDate }}</td>
                                <td>{{ item.returnDate }}</td>
                                <td>{{ item.name }}</td>
                                <td>{{ item.designation }}</td>
                                <td>{{ item.siteName }}</td>
                                <td>
                                    <button class="btn btn-primary btn-sm" @click="showItemsList(item)">
                                        View items list
                                    </button>
                                </td>
                                <td>
                                    <select class="form-select form-select-sm" v-model="item.laundrySlot">
                                        <option v-for="slot in laundrySlots" :key="slot" :value="slot">
                                            {{ slot }}
                                        </option>
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <!-- Items List Modal -->
    <div class="modal fade" :class="{ 'show': showModal }" v-if="showModal" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-sm">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Return Items List</h5>
                    <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <ul class="list-group">
                        <li class="list-group-item" v-for="(item, index) in selectedItems" :key="index">
                            <div class="d-flex flex-column">
                                <div class="d-flex justify-content-between align-items-center mb-2">
                                    <span :class="{ 'text-success': item.status === 'Processed' }">
                                        {{ item.name }} - {{ item.status }}
                                    </span>
                                </div>
                                <div class="btn-group btn-group-sm">
                                    <button class="btn btn-primary" @click="handleDirectToStock(item)">
                                        Add to Stock
                                    </button>
                                    <button class="btn btn-info" @click="handleLaundry(item)">
                                        Send to Laundry
                                    </button>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
                </div>
            </div>
        </div>
        <div class="modal-backdrop" @click="closeModal"></div>
    </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useToast } from 'vue-toast-notification';

const toast = useToast();

// Filter states
const filters = ref({
    site: '',
    dateFrom: '',
    dateTo: '',
    name: '',
    designation: ''
});

// Mock data (replace with actual data)
const sites = ['Site A', 'Site B', 'Site C'];
const designations = ['SG', 'Supervisor', 'Manager'];
const laundrySlots = ['7th', '14th', '27th'];

interface ReturnItem {
    issueDate: string;
    returnDate: string;
    name: string;
    designation: string;
    siteName: string;
    items: Array<{ name: string; status: string }>;
    laundry: string;
    laundrySlot: string;
}

interface ReturnItemDetail {
    name: string;
    status: string;
}

const returnItems = ref<ReturnItem[]>([
    {
        issueDate: '2024-01-01',
        returnDate: '2024-03-15',
        name: 'John Doe',
        designation: 'SG',
        siteName: 'Site A',
        items: [
            { name: 'Cargo Pant', status: 'Pending' },
            { name: 'T-shirt(OG)', status: 'Pending' },
            { name: 'Shoe(GS)', status: 'Pending' },
            { name: 'Vest', status: 'Nil' },
            { name: 'SG Cap', status: 'Nil' },
            { name: 'Whistle', status: 'Nil' },
            { name: 'SG Belt', status: 'Nil' },
            { name: 'Winter Jacket', status: 'Pending' },
            { name: 'Security Supervisor', status: 'Pending' },
            { name: 'Safari Shirt', status: 'Pending' },
            { name: 'Safari Pant', status: 'Pending' },
            { name: 'Shoe(GS)', status: 'Pending' },
            { name: 'Badge(SG)', status: 'Nil' },
            { name: 'Winter Jacket', status: 'Pending' },
            { name: 'Housekeeping', status: 'Pending' },
            { name: 'Blue T-shirt', status: 'Pending' },
            { name: 'Black Pant', status: 'Pending' },
            { name: 'Shoe(DMS)', status: 'Pending' },
            { name: 'Cap(HK)', status: 'Nil' },
            { name: 'Belt(HK)', status: 'Nil' },
            { name: 'HK sweaters', status: 'Pending' },
            { name: 'HK Aprons', status: 'Nil' },
            { name: 'Housekeeping supervisor', status: 'Pending' },
            { name: 'Formal Shirt', status: 'Pending' },
            { name: 'Formal Pant', status: 'Pending' },
            { name: 'Badge(HK)', status: 'Nil' },
            { name: 'Facility', status: 'Pending' },
            { name: 'Formal Shirt', status: 'Pending' },
            { name: 'Formal Pant', status: 'Pending' },
            { name: 'Formal Shoe', status: 'Pending' },
            { name: 'Badge(FM)', status: 'Nil' },
            { name: 'Black Pant', status: 'Pending' },
            { name: 'Black t-shirt', status: 'Pending' },
            { name: 'Shoe(DMS)', status: 'Pending' },
            { name: 'Belt', status: 'Pending' }
        ],
        laundry: 'No',
        laundrySlot: '7/14/21/28'
    }
]);


// Modal states
const showModal = ref(false);
const selectedItems = ref<ReturnItemDetail[]>([]);

// Computed filtered items
const filteredItems = computed(() => {
    return returnItems.value.filter(item => {
        const matchesSite = !filters.value.site || item.siteName.includes(filters.value.site);
        const matchesName = !filters.value.name || item.name.toLowerCase().includes(filters.value.name.toLowerCase());
        const matchesDesignation = !filters.value.designation || item.designation === filters.value.designation;

        let matchesDate = true;
        if (filters.value.dateFrom && filters.value.dateTo) {
            const returnDate = new Date(item.returnDate);
            const fromDate = new Date(filters.value.dateFrom);
            const toDate = new Date(filters.value.dateTo);
            matchesDate = returnDate >= fromDate && returnDate <= toDate;
        }

        return matchesSite && matchesName && matchesDesignation && matchesDate;
    });
});

// Methods
const showItemsList = (item: ReturnItem) => {
    selectedItems.value = item.items;
    showModal.value = true;
};

const handleLaundry = (item: ReturnItemDetail) => {
    // Implement laundry logic
    console.log('Send to laundry:', item);
    toast.success(`${item.name} sent to laundry`);
};

const handleDirectToStock = (item: ReturnItemDetail) => {
    // Implement direct to stock logic
    console.log('Direct to stock:', item);
    toast.success(`${item.name} added to stock`);
};

const closeModal = () => {
    showModal.value = false;
};
</script>

<style scoped>
.modal.show {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1050;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal.show::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px);
    z-index: -1;
}

.modal-dialog {
    width: 800px;
    width: 100%;
    margin: 1.75rem auto;
    position: relative;
    pointer-events: auto;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-content {
    max-height: calc(100vh - 3.5rem);
    overflow-y: auto;
}

.modal-backdrop {
    display: none;
}

.text-success {
    color: #28a745;
}

.list-group-item {
    transition: background-color 0.2s;
}

.list-group-item:hover {
    background-color: #f8f9fa;
}
</style>