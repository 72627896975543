<template>
    <Breadcrumbs main="Reports" title="Return Documents Report" />
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <h5>Return Documents Report</h5>
                <span>View and manage document returns across all sites</span>
            </div>
            <div class="card-body">
                <div class="table-responsive product-table custom-scrollbar">
                    <form class="dataTables_wrapper no-footer" @submit.prevent>
                        <!-- Search and Filters -->
                        <div class="row align-items-center mb-4">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label class="form-label">Search by Site Name</label>
                                    <input 
                                        type="text" 
                                        class="form-control" 
                                        v-model="filters.siteName" 
                                        placeholder="Enter site name..."
                                    >
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="form-label">Search by Date Range</label>
                                    <div class="d-flex gap-2">
                                        <input 
                                            type="date" 
                                            class="form-control" 
                                            v-model="filters.dateFrom"
                                            placeholder="From"
                                        >
                                        <input 
                                            type="date" 
                                            class="form-control" 
                                            v-model="filters.dateTo"
                                            placeholder="To"
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label class="form-label">Search by Employee Name</label>
                                    <input 
                                        type="text" 
                                        class="form-control" 
                                        v-model="filters.employeeName"
                                        placeholder="Enter employee name..."
                                    >
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group mt-4">
                                    <button class="btn btn-primary w-100" @click="handleSearch">
                                        <i class="fa fa-search me-2"></i>Search
                                    </button>
                                </div>
                            </div>
                        </div>

                        <!-- Table -->
                        <div class="table-wrapper mt-4">
                            <table class="display table table-striped table-hover table-bordered" id="docs-table">
                                <thead>
                                    <tr>
                                        <th>Docket No.</th>
                                        <th>Date of Joining</th>
                                        <th>Name of Employee</th>
                                        <th>Site Name</th>
                                        <th>Designation</th>
                                        <th>Doc Type</th>
                                        <th>Custodian Name</th>
                                        <th>Admin Custody</th>
                                        <th>Admin Custodian</th>
                                        <th>KYT Set Status</th>
                                    </tr>
                                </thead>
                                <tbody v-if="!filteredDocs.length">
                                    <tr>
                                        <td colspan="10" class="text-center">No matching records found</td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr v-for="(doc, index) in filteredDocs" :key="index">
                                        <td>{{ doc.docketNo }}</td>
                                        <td>{{ formatDate(doc.dateOfJoining) }}</td>
                                        <td>{{ doc.employeeName }}</td>
                                        <td>{{ doc.siteName }}</td>
                                        <td>{{ doc.designation }}</td>
                                        <td>{{ doc.docType }}</td>
                                        <td>{{ doc.custodianName }}</td>
                                        <td>
                                            <span :class="getStatusClass(doc.adminCustodyReceived)">
                                                {{ doc.adminCustodyReceived }}
                                            </span>
                                        </td>
                                        <td>{{ doc.adminCustodianName }}</td>
                                        <td>
                                            <span :class="getKYTStatusClass(doc.docsReturnKYT)">
                                                {{ doc.docsReturnKYT || 'Pending' }}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { format } from 'date-fns';

interface Doc {
    docketNo: string;
    dateOfJoining: Date;
    employeeName: string;
    siteName: string;
    designation: string;
    docType: string;
    custodianName: string;
    adminCustodyReceived: string;
    adminCustodianName: string;
    docsReturnKYT: string;
}

// Filters state
const filters = ref({
    siteName: '',
    dateFrom: '',
    dateTo: '',
    employeeName: ''
});

const generateDocketNumber = () => {
    const prefix = 'DOC';
    const randomNum = Math.floor(Math.random() * 100000).toString().padStart(5, '0');
    const year = new Date().getFullYear();
    return `${prefix}${randomNum}${year}`;
};

// Demo data
const docs = ref<Doc[]>([
    {
        docketNo: generateDocketNumber(),
        dateOfJoining: new Date('2024-01-01'),
        employeeName: 'John Doe',
        siteName: 'Trans Garden',
        designation: 'Security Guard',
        docType: 'ID Card',
        custodianName: 'Jane Smith',
        adminCustodyReceived: 'Yes',
        adminCustodianName: 'Admin User',
        docsReturnKYT: 'Completed'
    },
    // Add more sample data as needed
]);

const filteredDocs = computed(() => {
    return docs.value.filter(doc => {
        const matchesSite = !filters.value.siteName ||
            doc.siteName.toLowerCase().includes(filters.value.siteName.toLowerCase());

        const matchesEmployee = !filters.value.employeeName ||
            doc.employeeName.toLowerCase().includes(filters.value.employeeName.toLowerCase());

        const matchesDate = (!filters.value.dateFrom ||
            new Date(doc.dateOfJoining) >= new Date(filters.value.dateFrom)) &&
            (!filters.value.dateTo ||
                new Date(doc.dateOfJoining) <= new Date(filters.value.dateTo));

        return matchesSite && matchesEmployee && matchesDate;
    });
});

const formatDate = (date: Date) => {
    return format(new Date(date), 'dd-MM-yyyy');
};

const getStatusClass = (status: string) => {
    return {
        'badge rounded-pill bg-success': status === 'Yes',
        'badge rounded-pill bg-danger': status === 'No',
        'badge rounded-pill bg-warning': status === 'Pending'
    };
};

const getKYTStatusClass = (status: string) => {
    return {
        'badge rounded-pill bg-success': status === 'Completed',
        'badge rounded-pill bg-warning': status === 'Pending',
        'badge rounded-pill bg-info': status === 'In Progress'
    };
};

const handleSearch = () => {
    // Add any additional search logic here if needed
    console.log('Searching with filters:', filters.value);
};
</script>

<style scoped>
.table-responsive {
    margin-top: 1rem;
    position: relative;
}

.table-wrapper {
    overflow-x: auto;
    max-width: 100%;
    margin-bottom: 1rem;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    border-radius: 8px;
}

.table {
    width: 100%;
    white-space: nowrap;
    margin-bottom: 0;
}

.table th {
    background-color: #f8f9fa;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.85rem;
}

.table th,
.table td {
    padding: 1rem;
    vertical-align: middle;
}

.form-group {
    margin-bottom: 0;
}

.form-label {
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.custom-scrollbar {
    overflow: auto;
    scrollbar-width: none; /* Firefox */
}

.custom-scrollbar::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
}

.badge {
    padding: 0.5em 0.75em;
    font-weight: 500;
}

.card-header {
    background-color: #fff;
    border-bottom: 1px solid #dee2e6;
    padding: 1.5rem;
}

.card-header h5 {
    margin-bottom: 0.5rem;
    font-weight: 600;
}

.card-header span {
    color: #6c757d;
    font-size: 0.875rem;
}

.btn-primary {
    padding: 0.575rem 1rem;
}

.btn i {
    font-size: 0.875rem;
}
</style>