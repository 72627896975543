<script lang="js" setup>
import { ref, onMounted, Ref } from "vue";
import { fetchGetApi } from "@/util/api";
import jsonToExcel from '@/util/makeExcel';
import jsonToPdf from '@/util/makeExcel';

const allData = ref([]);
import Swal from 'sweetalert2';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { defineAsyncComponent } from 'vue';

const modalCode = defineAsyncComponent(() => import("@/components/roles/modalCode.vue"));
const elementsPerPage = ref(25);
const currentPage = ref(1);
const filterQuery = ref("");
const toast = useToast();

onMounted(async () => {
    fetchGetApi('api/cdw/getAllCdw').then((response) => {
        console.log(response.data);
        if (response && response.data && response.data.length > 0) {
            const sites = response.data
            console.log(sites);
            allData.value = sites;
            console.log(allData.value);
        } else {
            toast.error('Failed to fetch data')
        }
    });
});

function downloadExcel() {
    jsonToExcel(kypList.value, 'kyp.xlsx');
    // jsonToPdf(kypList.value, 'kyp.pdf');
}

function num_pages() {
    const query = filterQuery.value.toLowerCase();

    // Ensure allData.value is an array
    const data = Array.isArray(allData.value) ? allData.value : [];

    const filteredData = data.filter(kyt =>
        kyt.siteId.toLowerCase().includes(query) ||
        kyt.siteId.toLowerCase().includes(query)
    );

    return Math.ceil(filteredData.length / elementsPerPage.value);
}

function change_page(page) {
    currentPage.value = page;
}

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

function nextPage() {
    if (currentPage.value < num_pages()) {
        currentPage.value++;
    }
}

const dummyData = ref([
    {
        id: 1,
        date: "2023-10-01",
        managerName: "John Doe",
        sourceName: "Site A",
        empName: "Alice Smith",
        department: "Engineering",
        designation: "Engineer",
        underSiddar: "Yes",
        salary: "$5000",
        workingHour: "9 AM - 5 PM",
        stay: "Company Housing",
        esicEpf: "Yes",
        weeklyOff: "Saturday",
        food: "Provided",
        destinationSite: "Site B",
        destinationSalary: "$5200",
        destinationWorkingHour: "9 AM - 5 PM",
        destinationStay: "Company Housing",
        destinationEsicEpf: "Yes",
        destinationWeeklyOff: "Saturday",
        destinationFood: "Provided",
        reasonForTransfer: "Project Requirement"
    },
    {
        id: 2,
        date: "2023-10-02",
        managerName: "Jane Smith",
        sourceName: "Site B",
        empName: "Bob Johnson",
        department: "HR",
        designation: "HR Manager",
        underSiddar: "No",
        salary: "$6000",
        workingHour: "10 AM - 6 PM",
        stay: "Own Accommodation",
        esicEpf: "No",
        weeklyOff: "Sunday",
        food: "Not Provided",
        destinationSite: "Site C",
        destinationSalary: "$6200",
        destinationWorkingHour: "10 AM - 6 PM",
        destinationStay: "Own Accommodation",
        destinationEsicEpf: "No",
        destinationWeeklyOff: "Sunday",
        destinationFood: "Not Provided",
        reasonForTransfer: "Personal Request"
    }
]);
</script>

<template>
    <Breadcrumbs main="Apps" title="Transfer/Exchange" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row card card-body">
                <div class="table-responsive product-table custom-scrollbar">
                    <form class="no-footer">
                        <div class="mb-3 row justify-content-between">
                            <div class="col-xs-3 col-sm-auto">
                                <label for="table-complete-search"
                                    class="col-xs-12 col-sm-auto col-form-label">Search:</label>
                                <input id="table-complete-search" type="text" class="form-control" name="searchTerm"
                                    v-model="filterQuery">
                            </div>
                            <div class="col-xs-3 col-sm-auto">
                                <button type="button" class="btn btn-primary" @click="downloadExcel">Download Excel</button>
                            </div>
                        </div>
                        <table class="display table-striped table-hover table-bordered table" id="basic-1">
                            <thead>
                                <tr>
                                    <th class="sorting_asc" scope="col" style="width: 50px;">SL</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Date</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Manager name</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Source name</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Emp name</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Department</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Designation</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Under SIDDAR</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Salary</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Working hour</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Stay</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Esic/epf</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Weekly off</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Food</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Destination site</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Salary</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Working hour</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Stay</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Esic/epf</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Weekly off</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Food</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Reason for transfer/exchange</th>
                                </tr>
                            </thead>
        <tbody v-if="!dummyData.length">
            <tr class="odd">
                <td valign="top" colspan="22" class="dataTables_empty">No matching records found</td>
            </tr>
        </tbody>
        <tbody v-else>
            <tr v-for="(item, index) in dummyData" :key="item.id">
                <td>{{ index + 1 }}</td>
                <td>{{ item.date }}</td>
                <td>{{ item.managerName }}</td>
                <td>{{ item.sourceName }}</td>
                <td>{{ item.empName }}</td>
                <td>{{ item.department }}</td>
                <td>{{ item.designation }}</td>
                <td>{{ item.underSiddar }}</td>
                <td>{{ item.salary }}</td>
                <td>{{ item.workingHour }}</td>
                <td>{{ item.stay }}</td>
                <td>{{ item.esicEpf }}</td>
                <td>{{ item.weeklyOff }}</td>
                <td>{{ item.food }}</td>
                <td>{{ item.destinationSite }}</td>
                <td>{{ item.destinationSalary }}</td>
                <td>{{ item.destinationWorkingHour }}</td>
                <td>{{ item.destinationStay }}</td>
                <td>{{ item.destinationEsicEpf }}</td>
                <td>{{ item.destinationWeeklyOff }}</td>
                <td>{{ item.destinationFood }}</td>
                <td>{{ item.reasonForTransfer }}</td>
            </tr>
        </tbody>
                        </table>

                        <ul class="pagination py-2 justify-content-end pagination-primary">
                            <li class="page-item" @click="previousPage">
                                <a class="page-link">Previous</a>
                            </li>
                            <li class="page-item" v-for="i in num_pages()" :key="i"
                                :class="[i == currentPage ? 'active' : '']" @click="change_page(i)">
                                <a class="page-link">{{ i }}</a>
                            </li>
                            <li class="page-item" @click="nextPage">
                                <a class="page-link">Next</a>
                            </li>
                        </ul>
                    </form>
                    <modalCode />
                </div>
            </div>
        </div>
    </div>
</template>