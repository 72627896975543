import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { nextTick } from 'vue';

import BodyView from "@/layout/BodyView.vue"
import auth from "@/auth/authView.vue"
import login from "@/auth/loginPage.vue"

import home from "@/pages/pages/home.vue"
import Service from "@/pages/pages/services.vue"
import whyusPage from "@/pages/pages/whyus.vue"
import contactPage from "@/pages/pages/contact.vue"
import testimonials from "@/pages/pages/testimonials.vue"
import appsPage from "@/pages/pages/apps.vue"
import JoinUsIndex from "@/pages/pages/joinus.vue"
import clientsPage from "@/pages/pages/clients.vue"
import fileManager from "@/pages/filemaneger/fileManager.vue"


import indexUsers from "@/pages/users/list.vue"
import indexFieldEmployees from "@/pages/employees/field_employee_list.vue"
import indexOpertaionEmployees from "@/pages/employees/operation_employee_list.vue"


import indexDefault from "@/pages/dashbords/indexDefault.vue"
import indexContactData from "@/pages/contactsData/indexContact.vue"
import indexRoles from "@/pages/Roles/indexRoles.vue"
import kytList from "@/pages/kyts/indexKyts.vue"
import kytList2 from "@/pages/kyts/indexKyts2.vue"
import kytDetails from "@/pages/kyts/kytDetails.vue"

import indexLeaves from "@/pages/leaves/indexLeaves.vue"
import viewOfLeave from "@/pages/leaves/indexofLeaveView.vue"


import projects from "@/pages/projects/indexEntries.vue"
import projectsDetails from "@/pages/projects/view.vue"

import cdw from "@/pages/cdw/list.vue"
import kytTransfer from "@/pages/kyts/transfer/list.vue"
import changeKyt from "@/pages/kyts/change/list.vue"
import department from "@/pages/department/list.vue"
import services from "@/pages/services/list.vue"
import category from "@/pages/category/list.vue"
import subCategory from "@/pages/category/sub_list.vue"


import percentageReport from "@/pages/reports/percentage.vue"
import areaReport from "@/pages/reports/area.vue"
import areaProjectView from "@/pages/reports/area-project-view.vue"
import projectReport from "@/pages/reports/project-report.vue"
import kytChangeTransferReport from "@/pages/reports/kytChangeTransferReport.vue"
import criteriaReport from "@/pages/reports/criteria.vue"
import socReport from "@/pages/reports/soc-report.vue"
import competitorAnalysis from "@/pages/reports/competitior.vue"
import projectWith from "@/pages/reports/project-with.vue"
import projectionAndStatus from "@/pages/reports/projection-status.vue"
import justAnalysis from "@/pages/reports/just-analysis.vue"
import noProjectConverted from "@/pages/reports/no-of-project-converted.vue"
import cdwGiven from "@/pages/reports/cdw-given.vue"
import quotationsProposal from "@/pages/reports/quotations-proposal.vue"
import returnItemReport from "@/pages/reports/uniform/return-item-report.vue"
import laundryReport from '@/pages/reports/uniform/laundry-report.vue';
import stockReport from '@/pages/reports/stock-report.vue';

//operation start
import kypList from "@/pages/operation/kyp/list.vue"
import exchangeTransfer from "@/pages/operation/transfer-exchange/list.vue"
import reinstate from "@/pages/operation/reinstate.vue"
import siddar from "@/pages/operation/siddar.vue"
import AdvanceSalaryRequest from "@/pages/operation/advance-salary-request.vue"
import Frequency from "@/pages/operation/frequency.vue"
import manpowerContribution from "@/pages/operation/manpower-contribution.vue"
import reliverTaken from "@/pages/operation/reliver-taken.vue"
import marketingLead from "@/pages/operation/marketing-lead.vue"

import kytFAD from "@/pages/kyts/fad-list.vue"
import failedFAD from "@/pages/kyts/fad/failed.vue"
import achivedFAD from "@/pages/kyts/fad/achived.vue"
import defferedFAD from "@/pages/kyts/fad/deffered.vue"
import transferredFAD from "@/pages/kyts/fad/transferred.vue"

import formatFmmgc from "@/pages/operation/fmmgc/format.vue"
import groomingFmmgc from "@/pages/operation/fmmgc/grooming.vue"
import machineFmmgc from "@/pages/operation/fmmgc/machine.vue"
import manpowerFmmgc from "@/pages/operation/fmmgc/manpower.vue"
import sopKnowledgeFmmgc from "@/pages/operation/fmmgc/sop-knowledge.vue"
import customisedSopFmmgc from "@/pages/operation/fmmgc/customised-sop.vue"
import chimicalKnowledgeFmmgc from "@/pages/operation/fmmgc/chemical-knowledge.vue"
import basicHkFmmgc from "@/pages/operation/fmmgc/basic-hk-knowledge.vue"
import machineKnowledgeFmmgc from "@/pages/operation/fmmgc/machine-knowledge.vue"
import trainingAndBriefing from "@/pages/operation/training/security-training.vue"
import technicalTraining from "@/pages/operation/training/technical-training.vue"

import transfer from "@/pages/operation/transfer.vue"
import generalSettings from "@/pages/general-settings/general-settings.vue"

//----------------------------------------------
// Front Office
//----------------------------------------------


import indexpreJoin from "@/pages/front-office/pre-interview/prejoining.vue"
import indexInterview from "@/pages/front-office/interview/interview.vue"
import indexInvoice from "@/pages/front-office/joining-slip/invoice.vue"
import indexJoiningSlip from "@/pages/front-office/joining-slip/joining-slip.vue"
import interviewProcess from "@/pages/front-office/interview/interview-process.vue"
import joining from '@/pages/front-office/joining/joining.vue';

//Uniform imports
import returnUniform from '@/pages/uniform/return-uniform/return-uniform.vue';
import issueUniform from '@/pages/uniform/issue-uniform/issue-uniform.vue';
import issueUniformDetails from '@/components/uniform/issue-uniform/issue-uniform-details.vue';

//Stocks imports
import outOfStocks from '@/pages/stocks/out-of-stocks/out-of-stocks.vue';

//Materials imports
import orders from '@/pages/materials/orders/orders.vue';
import goods from '@/pages/materials/goods/goods.vue';

//Docs imports
import docs from '@/pages/docs/docs.vue';
import returnDocs from '@/components/docs/return-docs.vue';
import pvDetails from '@/pages/PV-details/pv-details.vue';
import pfEsicDetails from '@/pages/pf-esic-details/pf-esic-details.vue';
import assigningPanel from '@/pages/joining/assigning-panel/assigning-panel.vue';
import siteJoining from '@/pages/joining/site-joining/site-joining.vue';
import pendingDocs from '@/pages/pending-docs/pending-docs.vue';
import laundryReportPage from '@/pages/reports/laundry/laundry-report-page.vue';
import variationChart from '@/components/variation-chart/variation-chart.vue';
import returnDocument from '@/pages/documents/return-documents/return-document.vue';
import returnDocsReport from '@/pages/reports/return-documents.vue';
import uniformAccount from '@/pages/uniform-account/uniform-account.vue';
import joiningFinalization from '@/pages/joining-finalization/joining-finalization.vue';
import deductionSheet from '@/pages/deduction-sheet/deduction-sheet.vue';
import chaseList from '@/pages/chase-list/chase-list.vue';
import incentives from '@/pages/incentives/incentives.vue';
import allEmployeeList from '@/pages/employee-list/all-employee-list.vue';
import blackListedEmployee from '@/pages/employee-list/black-listed-employee.vue';
import employeeJobLife from '@/components/employee-list/all-employee-list/employee-job-life.vue';
import products from '@/pages/products/products.vue';

const routes: Array<RouteRecordRaw> = [

  {
    path: "/",
    component: BodyView,
    children: [
      {
        path: '',
        name: 'defaultRoot',
        component: indexDefault,
        meta: {
          title: 'SM Sarkar',
        }
      }
    ]
  },
  {
    path: "/auth",
    component: auth,
    children: [
      {
        path: "login",
        name: "login",
        component: login
      }
    ]
  },

  {
    path: "/pages",
    component: BodyView,
    children: [
      {
        path: "home",
        name: "home",
        component: home,
        meta: {
          title: 'Home Page',
        }
      },
      {
        path: "services",
        name: "Services page",
        component: Service,
        meta: {
          title: 'Services Page',
        }
      },
      {
        path: "whyus",
        name: "WhyUs",
        component: whyusPage,
        meta: {
          title: 'Why Us Page',
        }
      },
      {
        path: "contact-us",
        name: "Contact",
        component: contactPage,
        meta: {
          title: 'Contact Page',
        }
      },
      {
        path: "pending-docs",
        name: "Pending Docs",
        component: pendingDocs,
        meta: {
          title: 'Pending Docs',
        }
      },
      {
        path: "testimonials",
        name: "Testimonials",
        component: testimonials,
        meta: {
          title: 'Testimonials Page',
        }
      },
      {
        path: "apps",
        name: "Apps",
        component: appsPage,
        meta: {
          title: 'Apps Page',
        }
      },
      {
        path: "join-us",
        name: "JoinUs",
        component: JoinUsIndex,
        meta: {
          title: 'Join Us Page',
        }
      },
      {
        path: "clients",
        name: "Clients",
        component: clientsPage,
        meta: {
          title: 'Clients Page',
        }
      }
    ]
  },

  {
    path: "/dashboards",
    component: BodyView,
    children: [
      {
        path: "default",
        name: "default",
        component: indexDefault,
        meta: {
          title: 'Dashboard ',
        }
      }
    ]
  },
  {
    path: "/joining",
    component: BodyView,
    children: [
      {
        path: "assigning-panel",
        name: "Assigning Panel",
        component: assigningPanel,
        meta: {
          title: 'Assigning Panel',
        }
      },
      {
        path: "site-joining",
        name: "Site Joining",
        component: siteJoining,
        meta: {
          title: 'Site Joining',
        }
      },
    ]
  },
  {
    path: "/general-settings",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "General Settings ",
        component: generalSettings,
        meta: {
          title: 'General Settings',
        }
      }
    ]
  },
  {
    path: "/users",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "Users",
        component: indexUsers,
        meta: {
          title: 'Users List',
        }
      }
    ]
  },
  {
    path: "/employees",
    component: BodyView,
    children: [
      {
        path: "field",
        name: "Field Employees",
        component: indexFieldEmployees,
        meta: {
          title: 'Field Employees',
        }
      },
      {
        path: "operation",
        name: "Operation Employees",
        component: indexOpertaionEmployees,
        meta: {
          title: 'Operation Employees',
        }
      }
    ]
  },
  {
    path: "/permissions",
    component: BodyView,
    children: [
      {
        path: "roles",
        name: "Roles",
        component: indexRoles,
        meta: {
          title: 'Roles',
        }
      }
    ]
  },
  {
    path: "/leaves",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "Leaves",
        component: indexLeaves,
        meta: {
          title: 'Leaves',
        }
      },
      {
        path: "view/:id",
        name: "Leave Detail",
        component: viewOfLeave,
        meta: {
          title: 'Leave Detail',
        }
      }
    ]
  },
  {
    path: "/app",
    component: BodyView,
    children: [
      {
        path: "file_manager",
        name: "fileManager",
        component: fileManager,
        meta: {
          title: 'File Manager',
        }
      },

      {
        path: "contacts_data",
        name: "Contacts Form Data",
        component: indexContactData,
        meta: {
          title: 'Contacts Form Data',
        }
      },

    ]
  },
  {
    path: "/kyts",
    component: BodyView,
    children: [
      {
        path: "operation",
        name: "Operation Kyt",
        component: kytList2,
        meta: {
          title: 'Operation Kyt',
        }
      },
      {
        path: "marketing",
        name: "Marketing Kyt",
        component: kytList,
        meta: {
          title: 'Marketing Kyt',
        }
      },
      {
        path: "view/:id",
        name: "KYT Details",
        component: kytDetails,
        meta: {
          title: 'Kyt Details',
        }
      },
      {
        path: "transfer/request",
        name: "Transfer Kyt",
        component: kytTransfer,
        meta: {
          title: 'Transfer Kyt',
        }
      },
      {
        path: "change/request",
        name: "Change Kyt",
        component: changeKyt,
        meta: {
          title: 'Change Kyt',
        }
      },
      {
        path: "fad",
        name: "Fad Kyt",
        component: kytFAD,
        meta: {
          title: 'Fad Kyt',
        }
      },
      {
        path: "fad/failed",
        name: "Failed Fad",
        component: failedFAD,
        meta: {
          title: 'Failed Fad',
        }
      },
      {
        path: "fad/achived",
        name: "Achived Fad",
        component: achivedFAD,
        meta: {
          title: 'Achived Fad',
        }
      },
      {
        path: "fad/deffered",
        name: "Deffered Fad",
        component: defferedFAD,
        meta: {
          title: 'Deffered Fad',
        }
      },
      {
        path: "fad/transferred",
        name: "Transferred Fad",
        component: transferredFAD,
        meta: {
          title: 'Transferred Fad',
        }
      }
    ]
  },
  {
    path: "/projects",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "Projects",
        component: projects,
        meta: {
          title: 'Projects',
        }
      },
      {
        path: "view/:id",
        name: "Project Details",
        component: projectsDetails,
        meta: {
          title: 'Project Details',
        }
      }
    ]
  },
  {
    path: "/cdw",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "cdw",
        component: cdw,
        meta: {
          title: 'CDW',
        }
      },
    ]
  },
  {
    path: "/department",
    component: BodyView,
    children: [
      {
        path: "",
        name: "Department",
        component: department,
        meta: {
          title: 'Department',
        }
      },
    ]
  },
  {
    path: "/services",
    component: BodyView,
    children: [
      {
        path: "",
        name: "Services",
        component: services,
        meta: {
          title: 'Services',
        }
      },
    ]
  },
  {
    path: "/category",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "Category list",
        component: category,
        meta: {
          title: 'Category list',
        }
      }, {
        path: "subList",
        name: "Sub Category list",
        component: subCategory,
        meta: {
          title: 'Sub Category list',
        }
      }
    ]
  },
  {
    path: "/reports",
    component: BodyView,
    children: [
      {
        path: "training",
        children: [
          {
            path: "security-training",
            name: "Security Training",
            component: trainingAndBriefing,
            meta: {
              title: 'Security Training'
            }
          },
          {
            path: "technical-training",
            name: "Technical Training",
            component: technicalTraining,
            meta: {
              title: 'Technical Training'
            }
          }
        ]
      },
      {
        path: "fmmgc",
        children: [
          {
            path: "format",
            name: "Format Report",
            component: formatFmmgc,
            meta: {
              title: 'Format Report'
            }
          },
          {
            path: "grooming",
            name: "Grooming Report",
            component: groomingFmmgc,
            meta: {
              title: 'Grooming Report'
            }
          },
          {
            path: "machine",
            name: "Machine Report",
            component: machineFmmgc,
            meta: {
              title: 'Machine Report'
            }
          },
          {
            path: "manpower",
            name: "Manpower Report",
            component: manpowerFmmgc,
            meta:
            {
              title: 'Manpower Report'
            }
          },
          {
            path: "sop-knowledge",
            name: "SOP Knowledge Report",
            component: sopKnowledgeFmmgc,
            meta: {
              title: 'SOP Knowledge Report'
            }
          },
          {
            path: "customised-sop",
            name: "Customised SOP Report",
            component: customisedSopFmmgc,
            meta: {
              title: 'Customised SOP Report'
            }
          },
          {
            path: "chemical-knowledge",
            name: "Chemical Knowledge Report",
            component: chimicalKnowledgeFmmgc,
            meta: {
              title: 'Chemical Knowledge Report'
            }
          },
          {
            path: "basic-hk-knowledge",
            name: "Basic HK Knowledge Report",
            component: basicHkFmmgc,
            meta: {
              title: 'Basic HK Knowledge Report'
            }
          },
          {
            path: "machine-knowledge",
            name: "Machine Knowledge Report",
            component: machineKnowledgeFmmgc,

            meta: {
              title: 'Machine Knowledge Report'
            }
          }

        ]
      },
      {
        path: "uniform",
        children: [
          {
            path: "return-report",
            name: "Return Report",
            component: returnItemReport,
            meta: {
              title: 'Return Report'
            }
          },
          {
            path: "laundry-report",
            name: "Laundry Report",
            component: laundryReport,
            meta: {
              title: 'Laundry Report'
            }
          }
        ]
      },
      {
        path: "laundry",
        name: "Laundry Page Report",
        component: laundryReportPage,
        meta: {
          title: 'Laundry Page Report',
        }
      },
      {
        path: "return-documents",
        name: "Return Documents Report",
        component: returnDocsReport,
        meta: {
          title: 'Return Documents Report',
        }
      },
      {
        path: "percentage",
        name: "Percentage analysis",
        component: percentageReport,
        meta: {
          title: 'Percentage analysis',
        }
      },
      {
        path: "stock",
        name: "Stock Report",
        component: stockReport,
        meta: {
          title: 'Percentage analysis',
        }
      },
      {
        path: "area",
        name: "Area analysis",
        component: areaReport,
        meta: {
          title: 'Area analysis',
        }
      },
      {
        path: "area/area-project-view",
        name: "Area Project analysis",
        component: areaProjectView,
        meta: {
          title: 'Area - Project analysis',
        }
      },
      {
        path: "project",
        name: "Project report",
        component: projectReport,
        meta: {
          title: 'Project report',
        }
      },
      {
        path: "kyt-change-transfer",
        name: "Kyt change Transfer report",
        component: kytChangeTransferReport,
        meta: {
          title: 'Kyt Change Transfer',
        }
      },
      {
        path: "criteria",
        name: "Criteria",
        component: criteriaReport,
        meta: {
          title: 'Criteria',
        }
      },
      {
        path: "soc",
        name: "SOC analysis",
        component: socReport,
        meta: {
          title: 'SOC analysis',
        }
      },
      {
        path: "competitor",
        name: "Competitor analysis",
        component: competitorAnalysis,
        meta: {
          title: 'Competitor analysis',
        }
      },
      {
        path: "project-with",
        name: "Project With",
        component: projectWith,
        meta: {
          title: 'Project With',
        }
      },
      {
        path: "projection",
        name: "Projection and status",
        component: projectionAndStatus,
        meta: {
          title: 'Projection and status',
        }
      },
      {
        path: "just-analysis",
        name: "Just analysis",
        component: justAnalysis,
        meta: {
          title: 'JUST analysis',
        }
      },
      {
        path: "no-of-project-converted",
        name: "no-of-project-converted",
        component: noProjectConverted,
        meta: {
          title: 'Project Converted',
        }
      },
      {
        path: "cdw-given",
        name: "cdw-given",
        component: cdwGiven,
        meta: {
          title: 'Cdw Given',
        }
      },
      {
        path: "quotations-proposal",
        name: "quotations-proposal",
        component: quotationsProposal,
        meta: {
          title: 'Quotations Proposal',
        }
      }
    ]
  },
  {
    path: "/operation",
    component: BodyView,
    children: [
      {
        path: "transfer",
        name: "Transfer",
        component: transfer,
        meta: {
          title: 'Transfer'
        }

      },
      {
        path: "kyp",
        name: "Kyp List",
        component: kypList,
        meta: {
          title: 'Kyp List',
          requiresRole: 'admin'
        }
      },
      // {
      //   path: "exchange-transfer",
      //   name: "Exchange Transfer",
      //   component: exchangeTransfer,
      //   meta: {
      //     title: 'Exchange Transfer',
      //     requiresRole: 'user'
      //   }
      // },
      {
        path: "reinstate",
        name: "Reinstate",
        component: reinstate,
        meta: {
          title: 'Reinstate',
          requiresRole: 'user'
        }
      },
      {
        path: "siddar",
        name: "Siddar",
        component: siddar,
        meta: {
          title: 'SIDDAR'
        }
      },
      {
        path: "advance-salary-request",
        name: "Advance Salary Request",
        component: AdvanceSalaryRequest,
        meta: {
          title: 'Advance Salary Request'
        }
      },
      {
        path: "frequency",
        name: "Frequency",
        component: Frequency,
        meta: {
          title: 'Frequency'
        }
      },
      {
        path: "manpower-contribution",
        name: "manpower-contribution",
        component: manpowerContribution,
        meta: {
          title: 'Manpower Contribution'
        }
      },
      {
        path: "reliver-taken",
        name: "Reliver Taken",
        component: reliverTaken,
        meta: {
          title: 'Reliver Taken'
        }
      },
      {
        path: "marketing-lead",
        name: "Marketing Lead",
        component: marketingLead,
        meta: {
          title: 'Marketing Lead'
        }
      }
    ]
  },
  {
    path: "/front-office",
    component: BodyView,
    children:
      [
        {
          path: "pre-interview",
          name: " Pre Interview",
          component: indexpreJoin,
          meta: {
            title: "Pre Interview"
          }
        },
        {
          path: "Interview",
          name: " Interview",
          component: indexInterview,
          meta: {
            title: "Interview"
          }
        },
        {
          path: "joining",
          name: " Joining",
          component: joining,
          meta: {
            title: "Joining"
          }
        },
        {
          path: "joining-slip",
          name: " Joining Slip",
          component: indexJoiningSlip,
          meta: {
            title: "Joining Slip"
          }
        },
        {
          path: "print-joining-slip",
          name: "Print Joining Slip",
          component: indexInvoice,
          meta: {
            title: "Invoice"
          }
        },
        {
          path: "interview-process",
          name: "Interview Process",
          component: interviewProcess,
          meta: {
            title: "Interview Process"
          }
        }
      ]
  },
  {
    path: "/uniform",
    component: BodyView,
    children: [
      {
        path: "return",
        name: "Return Uniform",
        component: returnUniform,
        meta: {
          title: "Return Uniform"
        }
      },
      {
        path: "issue",
        name: "Issue Uniform",
        component: issueUniform,
        meta: {
          title: "Issue Uniform"
        }
      },
      {
        path: "issue/details",
        name: "Issue Uniform Details",
        component: issueUniformDetails,
        meta: {
          title: "Issue Uniform Details"
        }
      }
    ]
  },
  {
    path: "/uniform-account",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "UniformAccount",
        component: uniformAccount,
        meta: {
          title: "Uniform Account"
        }
      }
    ]
  },
  {
    path: "/joining-finalization",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "Joining Finalization",
        component: joiningFinalization,
        meta: {
          title: "Joining Finalization"
        }
      }
    ]
  },
  {
    path: "/products",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "Products",
        component: products,
        meta: {
          title: "Products"
        }
      }
    ]
  },
  {
    path: "/deduction-sheet",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "Deduction Sheet",
        component: deductionSheet,
        meta: {
          title: "Deduction Sheet"
        }
      }
    ]
  },
  {
    path: "/chase-list",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "Chase List",
        component: chaseList,
        meta: {
          title: "Chase List"
        }
      }
    ]
  },
  {
    path: "/incentives",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "Incentives",
        component: incentives,
        meta: {
          title: "Incentives"
        }
      }
    ]
  },
  {
    path: "/employee-list",
    component: BodyView,
    children: [
      {
        path: "all-employee-list",
        name: "All Employee List",
        component: allEmployeeList,
        meta: {
            title: "All Employee List"
        }
      },
      {
        path: "black-listed-employee",
        name: "Black Listed Employee",
        component: blackListedEmployee,
        meta: {
          title: "Black Listed Employee"
        }
      },
      {
        path: "employee-job-life",
        name: "Employee Job Life",
        component: employeeJobLife,
        meta: {
          title: "Employee Job Life"
        }
      }
    ]
  },
  {
    path: "/stocks",
    component: BodyView,
    children: [
      {
        path: "out-of-stocks",
        name: "Out of Stocks",
        component: outOfStocks,
        meta: {
          title: "Out of Stocks"
        }
      }
    ]
  },
  {
    path: "/docs",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "Docs",
        component: docs,
        meta: {
          title: "Docs"
        }
      },
      {
        path: "return",
        name: "Return Docs",
        component: returnDocs,
        meta: {
          title: "Return Docs"
        }
      }
    ]
  },
  {
    path: "/documents",
    component: BodyView,
    children: [
      {
        path: "return-documents",
        name: "Return Documents",
        component: returnDocument,
        meta: {
          title: "Return Documents"
        }
      }
    ]
  },
  {
    path: "/pf-esic-details",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "Pf Esic Details",
        component: pfEsicDetails,
        meta: {
          title: "PF/ESIC Details"
        }
      }
    ]
  },
  {
    path: "/pv-details",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "PV Details",
        component: pvDetails,
        meta: {
          title: "PV Details"
        }
      },
    ]
  },
  {
    path: "/variation-chart",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "Variation Chart",
        component: variationChart,
        meta: {
          title: "Variation Chart"
        }
      },
    ]
  },
  {
    path: "/materials",
    component: BodyView,
    children: [
      {
        path: "orders",
        name: "Orders",
        component: orders,
        meta: {
          title: "Orders"
        }
      },
      {
        path: "goods",
        name: "Goods",
        component: goods,
        meta: {
          title: "Goods"
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkExactActiveClass: 'active',
})
router.afterEach((to) => {
  nextTick(() => {
    document.title = to.meta.title ? to.meta.title as string : 'Default Title';
  });
});
router.beforeEach((to, from, next) => {
  const path = ['/auth/login', '/auth/register'];
  // const userRole=['Exchange Transfer','Kyp List'];
  // if((typeof to.name === 'string' && userRole.includes(to.name)) ||to.path=='/auth/login'||to.path=='/'){
  //   return next()
  // }else{
  //   return next('/auth/login')
  // }
  if (path.includes(to.path) || localStorage.getItem('user')) {
    return next();
  }
  next('/auth/login');
});

export default router
