<script lang="js" setup>
import { ref, onMounted, Ref } from "vue";
import { fetchGetApi } from "@/util/api";

const allData = ref([]);
import Swal from 'sweetalert2';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { defineAsyncComponent } from 'vue';

const modalCode = defineAsyncComponent(() => import("@/components/roles/modalCode.vue"));
const elementsPerPage = ref(25);
const currentPage = ref(1);
const filterQuery = ref("");
const toast = useToast();

onMounted(async () => {
    fetchGetApi('api/cdw/getAllCdw').then((response) => {
        console.log(response.data);
        if (response && response.data) {
            const sites = response.data
            console.log(sites);
            allData.value = sites;
            console.log(allData.value);
        } else {
            toast.error('Failed to fetch data')
        }
    });
});

</script>

<template>
    <Breadcrumbs main="Apps" title="CDW" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row">
                <div class="table-responsive product-table custom-scrollbar">
                    <form class="no-footer">
                        <div class="mb-3 row justify-content-between">
                            <div class="col-xs-3 col-sm-auto">
                                <label for="table-complete-search"
                                    class="col-xs-12 col-sm-auto col-form-label">Search:</label>
                                <input id="table-complete-search" type="text" class="form-control" name="searchTerm"
                                    v-model="filterQuery">
                            </div>
                        </div>
                                                <table class="display table-striped table-hover table-bordered table" id="basic-1">
                            <thead>
                                <tr>
                                    <th class="sorting_asc" scope="col" style="width: 50px;">SL</th>
                                    <th class="sorting_asc" scope="col">Site ID</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Department</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Given By</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Managers</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Higher Auth</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Categories</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Sub Categories</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Work Description</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Solution</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Work Date</th>
                                </tr>
                            </thead>
                            <tbody v-if="!allData.length">
                                <tr class="odd">
                                    <td valign="top" colspan="13" class="dataTables_empty">No matching records found</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr class="odd" v-for="(row, index) in allData" :key="index">
                                    <td class="sorting_1" style="width: 10px;">{{ index + 1 }}</td>
                                    <td class="sorting_1">{{ row.siteId }}</td>
                                    <td class="sorting_1">{{ row.deptId }}</td>
                                    <td class="sorting_1">
                                        {{ row.byUserId }}
                                    </td>
                                    <td class="sorting_1">
                                        <ul>
                                            <li v-for="manager in row.managerIds" :key="manager">{{ manager }}</li>
                                        </ul>
                                    </td>
                                    <td class="sorting_1">
                                        <ul>
                                            <li v-for="auth in row.alertHigherAuthIds" :key="auth">{{ auth }}</li>
                                        </ul>
                                    </td>
                                    <td class="sorting_1">
                                        <ul>
                                            <li v-for="category in row.categoryIds" :key="category">{{ category }}</li>
                                        </ul>
                                    </td>
                                    <td class="sorting_1">
                                        <ul>
                                            <li v-for="subCategory in row.subCategoryIds" :key="subCategory">{{ subCategory }}</li>
                                        </ul>
                                    </td>
                                    <td class="sorting_1">{{ row.wordDescription }}</td>
                                    <td class="sorting_1">{{ row.solution }}</td>
                                    <td class="sorting_1 text-nowrap">{{row.workDate&&row.workDate.length > 16 ? row.workDate.substring(0, 16) : row.workDate }}</td>
                                    <!-- <td>
                                        <router-link :to="`view/${row.id}`" class="text-primary me-2" title="View" target="_blank" style="cursor: pointer; font-size:20px;">
                                            <i class="fas fa-eye"></i>
                                        </router-link>
                                    </td> -->
                                </tr>
                            </tbody>
                        </table>
<!--                         
                        <ul class="pagination py-2 justify-content-end pagination-primary">
                            <li class="page-item" @click="previousPage">
                                <a class="page-link">Previous</a>
                            </li>
                            <li class="page-item" v-for="i in num_pages()" :key="i"
                                :class="[i == currentPage ? 'active' : '']" @click="change_page(i)">
                                <a class="page-link">{{ i }}</a>
                            </li>
                            <li class="page-item" @click="nextPage">
                                <a class="page-link">Next</a>
                            </li>
                        </ul> -->
                    </form>
                    <modalCode />
                </div>
            </div>
        </div>
    </div>
</template>
