<template>
    <Breadcrumbs main="Reports" title="Laundry Report" />
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <!-- Filters -->
                <div class="row mb-4">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Search by Laundry Date Range</label>
                            <div class="d-flex gap-2">
                                <input type="date" class="form-control" v-model="filters.laundryDateFrom">
                                <span class="align-self-center">to</span>
                                <input type="date" class="form-control" v-model="filters.laundryDateTo">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Search by Returned Items</label>
                            <input type="text" class="form-control" v-model="filters.returnedItem"
                                placeholder="Enter item name">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Search by Receiving Date Range</label>
                            <div class="d-flex gap-2">
                                <input type="date" class="form-control" v-model="filters.receivingDateFrom">
                                <span class="align-self-center">to</span>
                                <input type="date" class="form-control" v-model="filters.receivingDateTo">
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Table -->
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Date of laundry</th>
                                <th>Name of returned items</th>
                                <th>Total Pieces for laundry</th>
                                <th>Rejected pcs</th>
                                <th>Total pieces for stock</th>
                                <th>Date of Receiving</th>
                                <th>Add to stock</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in filteredItems" :key="index">
                                <td>{{ item.laundryDate }}</td>
                                <td>{{ item.itemName }}</td>
                                <td>
                                    {{ item.totalPieces }}
                                    <button class="btn btn-link btn-sm" @click="showSizesModal(item)">
                                        (click to see sizes)
                                    </button>
                                </td>
                                <td>
                                    Nos: {{ item.rejectedPieces }}
                                    <button v-if="item.rejectedPieces > 0" class="btn btn-link btn-sm"
                                        @click="showRejectedModal(item)">
                                        (view details)
                                    </button>
                                </td>
                                <td>{{ calculateTotalForStock(item) }}</td>
                                <td>{{ item.receivingDate || '-' }}</td>
                                <td>
                                    <button class="btn btn-primary btn-sm" @click="handleAddToStock(item)"
                                        :disabled="item.addedToStock">
                                        {{ item.addedToStock ? 'Added' : 'Add' }}
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <!-- Sizes Modal -->
    <div class="modal fade" :class="{ 'show': showModal }" v-if="showModal" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Size Distribution</h5>
                    <button type="button" class="btn-close" @click="closeModal"></button>
                </div>
                <div class="modal-body">
                    <ul class="list-group">
                        <li class="list-group-item" v-for="(count, size) in selectedItem?.sizeDistribution" :key="size">
                            {{ size }}: {{ count }} pieces
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!-- Rejected Pieces Modal -->
    <div class="modal fade" :class="{ 'show': isRejectedModalVisible }" v-if="isRejectedModalVisible" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Rejected Pieces - {{ selectedItem?.itemName }}</h5>
                    <button type="button" class="btn-close" @click="closeRejectedModal"></button>
                </div>
                <div class="modal-body">
                    <div class="rejected-info">
                        <p class="mb-2">Total Rejected: {{ selectedItem?.rejectedPieces }}</p>
                        <div class="size-list">
                            <h6 class="mb-3">Rejected Sizes:</h6>
                            <ul class="list-group">
                                <li class="list-group-item" v-for="size in selectedItem?.rejectedSizes" :key="size">
                                    {{ size }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Add to Stock Modal -->
    <div class="modal fade" :class="{ 'show': showStockModal }" v-if="showStockModal" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Add to Stock - {{ selectedItem?.itemName }}</h5>
                    <button type="button" class="btn-close" @click="showStockModal = false"></button>
                </div>
                <div class="modal-body">
                    <div class="size-distribution">
                        <h6 class="mb-3">Select quantities for each size:</h6>
                        <div class="mb-3" v-for="(count, size) in selectedItem?.sizeDistribution" :key="size">
                            <label class="form-label">Size {{ size }}</label>
                            <div class="input-group">
                                <input type="number" class="form-control" v-model="selectedSizes[size]" :max="count"
                                    min="0">
                                <span class="input-group-text">/ {{ count }} available</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="showStockModal = false">Cancel</button>
                    <button type="button" class="btn btn-primary" @click="confirmAddToStock">Add to Stock</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useToast } from 'vue-toast-notification';

const toast = useToast();

interface LaundryItem {
    laundryDate: string;
    itemName: string;
    totalPieces: number;
    sizeDistribution: Record<string, number>;
    rejectedPieces: number;
    rejectedSizes: string[];
    receivingDate: string | null;
    addedToStock: boolean;
}

// Filter states
const filters = ref({
    laundryDateFrom: '',
    laundryDateTo: '',
    returnedItem: '',
    receivingDateFrom: '',
    receivingDateTo: ''
});

const laundryItems = ref<LaundryItem[]>([
    {
        laundryDate: '2024-03-07',
        itemName: 'Cargo pant',
        totalPieces: 4,
        sizeDistribution: {
            'S': 1,
            'M': 2,
            'L': 1
        },
        rejectedPieces: 1,
        rejectedSizes: ['M'],
        receivingDate: '2024-03-10',
        addedToStock: false
    },
    {
        laundryDate: '2024-03-14',
        itemName: 'T-shirt(OG)',
        totalPieces: 3,
        sizeDistribution: {
            'M': 2,
            'L': 1
        },
        rejectedPieces: 0,
        rejectedSizes: [],
        receivingDate: null,
        addedToStock: false
    }
]);

// Modal states
const showModal = ref(false);
const selectedItem = ref<LaundryItem | null>(null);
const isRejectedModalVisible = ref(false);
const showStockModal = ref(false);
const selectedSizes = ref<Record<string, number>>({});

// Computed filtered items
const filteredItems = computed(() => {
    return laundryItems.value.filter(item => {
        const matchesLaundryDate = (!filters.value.laundryDateFrom || item.laundryDate >= filters.value.laundryDateFrom) &&
            (!filters.value.laundryDateTo || item.laundryDate <= filters.value.laundryDateTo);

        const matchesReceivingDate = (!filters.value.receivingDateFrom ||
            (item.receivingDate && item.receivingDate >= filters.value.receivingDateFrom)) &&
            (!filters.value.receivingDateTo ||
                (item.receivingDate && item.receivingDate <= filters.value.receivingDateTo));

        const matchesItem = !filters.value.returnedItem ||
            item.itemName.toLowerCase().includes(filters.value.returnedItem.toLowerCase());

        return matchesLaundryDate && matchesReceivingDate && matchesItem;
    });
});
// Methods
const calculateTotalForStock = (item: LaundryItem): number => {
    return item.totalPieces - item.rejectedPieces;
};

const showSizesModal = (item: LaundryItem): void => {
    selectedItem.value = item;
    showModal.value = true;
};

const showRejectedModal = (item: LaundryItem): void => {
    selectedItem.value = item;
    isRejectedModalVisible.value = true;
};

const closeRejectedModal = () => {
    isRejectedModalVisible.value = false;
    selectedItem.value = null;
};

const closeModal = () => {
    showModal.value = false;
    selectedItem.value = null;
};

const handleAddToStock = (item: LaundryItem): void => {
    selectedItem.value = item;
    selectedSizes.value = {}; // Reset selected sizes
    // Initialize with available sizes
    Object.keys(item.sizeDistribution).forEach(size => {
        selectedSizes.value[size] = 0;
    });
    showStockModal.value = true;
};

const confirmAddToStock = () => {
    if (!selectedItem.value) return;

    selectedItem.value.addedToStock = true;
    toast.success(`${selectedItem.value.itemName} added to stock`);
    showStockModal.value = false;
    selectedItem.value = null;
};
</script>

<style scoped>
.modal.show {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1050;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal.show::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px);
    z-index: -1;
}

.modal-dialog {
    max-width: 400px;
    width: 100%;
    margin: 1.75rem auto;
    position: relative;
    pointer-events: auto;
}

.modal-content {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    display: flex;
    flex-direction: column;
}

.modal-body {
    max-height: 60vh;
    overflow-y: auto;
    padding: 1rem;
}

.list-group {
    margin: 0;
    padding: 0;
}

.list-group-item {
    display: flex;
    justify-content: space-between;
    padding: 0.75rem 1rem;
    border: 1px solid #dee2e6;
    margin-bottom: -1px;
}

.list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}
</style>