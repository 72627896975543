<template>
    <div class="container-fluid">
        <div class="card">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h5>Stock Report - Department wise</h5>
                <button class="btn btn-primary" @click="downloadExcel">
                    Download Excel
                </button>
            </div>
            <div class="card-body">
                <!-- Department Selection -->
                <div class="row mb-4">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="form-label">Select Department</label>
                            <select class="form-select" v-model="selectedDepartment">
                                <option value="">Choose Department</option>
                                <option v-for="dept in departments" :key="dept" :value="dept">
                                    {{ dept }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <!-- Stock Table -->
                <div v-if="selectedDepartment" class="table-responsive">
                    <p class="text-muted mb-2">(multiple selections can be done)</p>
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Name of the uniform parts</th>
                                <th>Total Quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in filteredStockItems" :key="item.name">
                                <td>{{ item.name }}</td>
                                <td>
                                    <button 
                                        class="btn btn-link"
                                        @click="showDetailedStock(item)"
                                    >
                                        {{ item.quantity }}
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!-- Detailed Stock Modal -->
        <div class="modal fade" :class="{ 'show': showModal }" v-if="showModal">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Detailed Stock Report - {{ selectedItem?.name }} ({{ selectedDepartment }})
                        </h5>
                        <button type="button" class="btn-close" @click="closeModal"></button>
                    </div>
                    <div class="modal-body">
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Size</th>
                                        <th>Quantity</th>
                                        <th>Condition</th>
                                        <th>Location</th>
                                        <th>Last Updated</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="detail in selectedItemDetails" :key="detail.size">
                                        <td>{{ detail.size }}</td>
                                        <td>{{ detail.quantity }}</td>
                                        <td>
                                            <span :class="getConditionClass(detail.condition)">
                                                {{ detail.condition }}
                                            </span>
                                        </td>
                                        <td>{{ detail.location }}</td>
                                        <td>{{ formatDate(detail.lastUpdated) }}</td>
                                        <td>
                                            <span :class="getStatusClass(detail.status)">
                                                {{ detail.status }}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td><strong>Total</strong></td>
                                        <td colspan="5">
                                            <strong>{{ getTotalQuantity() }}</strong> pieces
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" @click="closeModal">Close</button>
                        <button class="btn btn-primary" @click="downloadDetailedExcel">
                            Download Detailed Report
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { format } from 'date-fns';
import * as XLSX from 'xlsx';
import { useToast } from 'vue-toast-notification';

const toast = useToast();
const showModal = ref(false);
const selectedDepartment = ref('');
const selectedItem = ref<any>(null);
const selectedItemDetails = ref<any[]>([]);

const departments = ref([
    'Security',
    'Housekeeping',
    'Facility Management'
]);

// Stock data organized by department
const stockData = ref({
    Security: [
        { name: 'Olive T-shirt', quantity: 100 },
        { name: 'Cargo Pant', quantity: 50 },
        { name: 'Cap', quantity: 25 },
        { name: 'Leather Shoe', quantity: 25 },
        { name: 'SS Cap', quantity: 10 },
        { name: 'SS Belt', quantity: 10 },
        { name: 'Whistle', quantity: 2 },
        { name: 'Safari Shirt', quantity: 5 },
        { name: 'Safari Pant', quantity: 5 },
        { name: 'Winter Jacket', quantity: 10 },
        { name: 'Badge(SG)', quantity: 2 }
    ],
    Housekeeping: [
        { name: 'Blue T-shirt', quantity: 20 },
        { name: 'Black Pant', quantity: 24 },
        { name: 'TVSE Shoe', quantity: 26 },
        { name: 'Formal Shirt', quantity: 20 },
        { name: 'Formal Pant', quantity: 1 },
        { name: 'Formal Shoe', quantity: 5 },
        { name: 'Black T-shirt', quantity: 7 },
        { name: 'HK Cap', quantity: 9 },
        { name: 'HK Belt', quantity: 5 },
        { name: 'HK Sweater', quantity: 5 },
        { name: 'HK Apron', quantity: 2 },
        { name: 'Badge(HK)', quantity: 4 }
    ],
    'Facility Management': [
        { name: 'Formal Shirt', quantity: 5 },
        { name: 'Formal Pant', quantity: 4 },
        { name: 'Formal Shoe', quantity: 3 },
        { name: 'Badge(FM)', quantity: 5 }
    ]
});

const filteredStockItems = computed(() => {
    if (!selectedDepartment.value) return [];
    return stockData.value[selectedDepartment.value as keyof typeof stockData.value] || [];
});

const showDetailedStock = async (item: any) => {
    selectedItem.value = item;
    // Mock detailed data - replace with actual API call
    selectedItemDetails.value = [
        { 
            size: 'S', 
            quantity: 10, 
            condition: 'New', 
            location: 'Main Store',
            status: 'In Stock',
            lastUpdated: new Date() 
        },
        { 
            size: 'M', 
            quantity: 15, 
            condition: 'Used', 
            location: 'Main Store',
            status: 'In Stock',
            lastUpdated: new Date() 
        },
        { 
            size: 'L', 
            quantity: 20, 
            condition: 'New', 
            location: 'Site Store',
            status: 'Reserved',
            lastUpdated: new Date() 
        },
        { 
            size: 'XL', 
            quantity: 5, 
            condition: 'Damaged', 
            location: 'Main Store',
            status: 'In Laundry',
            lastUpdated: new Date() 
        }
    ];
    showModal.value = true;
};

const closeModal = () => {
    showModal.value = false;
    selectedItem.value = null;
};

const formatDate = (date: Date) => {
    return format(date, 'dd-MM-yyyy HH:mm');
};

const getConditionClass = (condition: string) => {
    const classes = {
        'New': 'badge bg-success',
        'Used': 'badge bg-warning',
        'Damaged': 'badge bg-danger'
    };
    return classes[condition as keyof typeof classes] || 'badge bg-secondary';
};

const getStatusClass = (status: string) => {
    const classes = {
        'In Stock': 'badge bg-success',
        'Reserved': 'badge bg-warning',
        'In Laundry': 'badge bg-info'
    };
    return classes[status as keyof typeof classes] || 'badge bg-secondary';
};

const getTotalQuantity = () => {
    return selectedItemDetails.value.reduce((total, item) => total + item.quantity, 0);
};

const downloadExcel = () => {
    if (!selectedDepartment.value) {
        toast.error('Please select a department first');
        return;
    }

    const worksheet = XLSX.utils.json_to_sheet(filteredStockItems.value);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, `${selectedDepartment.value} Stock`);
    XLSX.writeFile(workbook, `${selectedDepartment.value}_stock_report.xlsx`);
};

const downloadDetailedExcel = () => {
    if (!selectedItem.value) return;

    const worksheet = XLSX.utils.json_to_sheet(selectedItemDetails.value);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, `${selectedItem.value.name} Details`);
    XLSX.writeFile(workbook, `${selectedItem.value.name}_detailed_stock.xlsx`);
};
</script>

<style scoped>
.table th {
    background-color: #f8f9fa;
    font-weight: bold;
}

.table td, .table th {
    vertical-align: middle;
    padding: 0.75rem;
}

.text-muted {
    font-style: italic;
}

.btn-link {
    text-decoration: none;
    color: #007bff;
    padding: 0;
}

.btn-link:hover {
    text-decoration: underline;
    color: #0056b3;
}

.modal.show {
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-dialog {
    margin: 1.75rem auto;
}

.modal-lg {
    max-width: 800px;
}

.badge {
    font-size: 0.875em;
    padding: 0.35em 0.65em;
}

@media print {
    .modal-dialog {
        max-width: 100%;
        margin: 0;
    }

    .modal-footer {
        display: none;
    }

    .btn-close {
        display: none;
    }
}
</style>