<template>
    <div class="panel-container">
        <div class="card">
            <div class="card-header">
                <h5>Price Variation Analysis</h5>
            </div>
            <div class="card-body">
                <!-- Filters -->
                <div class="row mb-4 justify-content-center">
                    <div class="col-md-3 mx-2">
                        <label class="form-label">Select Vendor</label>
                        <select class="form-select" v-model="selectedVendor">
                            <option value="">All Vendors</option>
                            <option v-for="vendor in vendors" :key="vendor" :value="vendor">
                                {{ vendor }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-3 mx-2">
                        <label class="form-label">Select Item</label>
                        <select class="form-select" v-model="selectedItem">
                            <option value="">All Items</option>
                            <option v-for="item in items" :key="item" :value="item">
                                {{ item }}
                            </option>
                        </select>
                    </div>
                </div>

                <!-- Chart -->
                <div class="chart-wrapper">
                    <div class="chart-container">
                        <canvas ref="chartRef"></canvas>
                    </div>
                </div>

                <!-- Tabulation -->
                <div class="table-responsive mt-4">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Item</th>
                                <th>Vendor</th>
                                <th>Cost Price</th>
                                <th>Selling Price</th>
                                <th>Critical Level</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(record, index) in filteredData" :key="index">
                                <td>{{ formatDate(record.date) }}</td>
                                <td>{{ record.item }}</td>
                                <td>{{ record.vendor }}</td>
                                <td>₹{{ record.costPrice }}</td>
                                <td>₹{{ record.sellingPrice }}</td>
                                <td>{{ record.criticalLevel }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed, watch } from 'vue';
import { Chart, ChartData, ChartOptions } from 'chart.js/auto';
import { format } from 'date-fns';

interface PriceRecord {
    date: string;
    item: string;
    vendor: string;
    costPrice: number;
    sellingPrice: number;
    criticalLevel: number;
}

// Dummy data
const priceData = ref<PriceRecord[]>([
    {
        date: '2024-02-01',
        item: 'Uniform',
        vendor: 'Vendor A',
        costPrice: 1000,
        sellingPrice: 1200,
        criticalLevel: 900
    },
    {
        date: '2024-02-02',
        item: 'Uniform',
        vendor: 'Vendor A',
        costPrice: 1050,
        sellingPrice: 1250,
        criticalLevel: 900
    },
    {
        date: '2024-02-01',
        item: 'Shoes',
        vendor: 'Vendor B',
        costPrice: 800,
        sellingPrice: 1000,
        criticalLevel: 750
    }
]);

const selectedVendor = ref('');
const selectedItem = ref('');
const chartRef = ref<HTMLCanvasElement | null>(null);
const chart = ref<Chart | null>(null);

const vendors = computed(() => [...new Set(priceData.value.map(record => record.vendor))]);
const items = computed(() => [...new Set(priceData.value.map(record => record.item))]);

const filteredData = computed(() => {
    return priceData.value.filter(record => {
        if (selectedVendor.value && record.vendor !== selectedVendor.value) return false;
        if (selectedItem.value && record.item !== selectedItem.value) return false;
        return true;
    });
});

const formatDate = (date: string) => {
    return format(new Date(date), 'dd/MM/yyyy');
};

const updateChart = () => {
    if (!chartRef.value || !filteredData.value.length) return;

    const dates = filteredData.value.map(record => formatDate(record.date));
    const costPrices = filteredData.value.map(record => record.costPrice);
    const sellingPrices = filteredData.value.map(record => record.sellingPrice);
    const criticalLevels = filteredData.value.map(record => record.criticalLevel);

    const data: ChartData = {
        labels: dates,
        datasets: [
            {
                label: 'Cost Price',
                data: costPrices,
                borderColor: '#ff6384',
                backgroundColor: 'rgba(255, 99, 132, 0.1)',
                borderWidth: 2,
                fill: true,
                tension: 0.4
            },
            {
                label: 'Selling Price',
                data: sellingPrices,
                borderColor: '#36a2eb',
                backgroundColor: 'rgba(54, 162, 235, 0.1)',
                borderWidth: 2,
                fill: true,
                tension: 0.4
            },
            {
                label: 'Critical Level',
                data: criticalLevels,
                borderColor: '#ffce56',
                backgroundColor: 'rgba(255, 206, 86, 0.1)',
                borderWidth: 2,
                fill: true,
                tension: 0.4
            }
        ]
    };

    const options: ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    padding: 20,
                    font: {
                        size: 14
                    }
                }
            }
        },
        scales: {
            y: {
                beginAtZero: false,
                title: {
                    display: true,
                    text: 'Price (₹)',
                    font: {
                        size: 14,
                        weight: 'bold'
                    }
                },
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)'
                }
            },
            x: {
                title: {
                    display: true,
                    text: 'Date',
                    font: {
                        size: 14,
                        weight: 'bold'
                    }
                },
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)'
                }
            }
        }
    };

    if (chart.value) {
        chart.value.destroy();
    }

    chart.value = new Chart(chartRef.value, {
        type: 'line',
        data,
        options
    });
};

watch([selectedVendor, selectedItem], () => {
    updateChart();
});

onMounted(() => {
    updateChart();
});
</script>

<style scoped>
.panel-container {
    padding: 20px;
}

.card {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.chart-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.chart-container {
    position: relative;
    height: 600px;
    width: 90%;
    margin: 0 auto;
}

.table {
    font-size: 0.875rem;
    margin-top: 2rem;
}

.table th {
    white-space: nowrap;
    vertical-align: middle;
    background-color: #f8f9fa;
}

.form-select {
    border-radius: 6px;
    border: 1px solid #dee2e6;
}

.form-select:focus {
    border-color: #86b7fe;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
</style>