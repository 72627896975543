
import { ref } from 'vue'

export async function fetchPostApi(endpoint, query={}) {
  console.log("fetchPostApi: "+endpoint)

  try {
    const url = process.env.VUE_APP_BASE_URL
    // const accessToken = useCookie('accessToken').value

    try {
      const response = await fetch(url + endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          // 'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify(query),
      })
      const data = await response.json()
      console.log("response: "+data)

      return { response, data }
      
    } catch (error) {
      console.error('Error:', error)
    }

  } catch (error) {
    // Handle unexpected errors (e.g., network issues)
    console.error('An error occurred:', error)
  }
}
export async function fetchGetApi(endpoint, query) {
  console.log("fetchGetApi: "+endpoint)
  try {
    const url = process.env.VUE_APP_BASE_URL

    // const accessToken = useCookie('accessToken').value

    try {
      const response = await fetch(url + endpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          // 'Authorization': `Bearer ${accessToken}`,
        },
      })

      const data = await response.json()

      return { response, data }
      
    } catch (error) {
      console.error('Error:', error)
    }

  } catch (error) {
    // Handle unexpected errors (e.g., network issues)
    console.error('An error occurred:', error)
  }
}


export async function fetchUploadApi(endpoint, query) {
  try {
    let subdomain = useCookie('subdomain').value || ''
    const url = import.meta.env.VUE_APP_BASE_URL
    const accessToken = useCookie('accessToken').value

    try {
      const response = await fetch(url + endpoint, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
        body: query,
      })

      const data = await response.json()

      return { response, data }
      
    } catch (error) {
      console.error('Error:', error)
    }

  } catch (error) {
    // Handle unexpected errors (e.g., network issues)
    console.error('An error occurred:', error)
  }
}

export const stateList = [
  "Andaman and Nicobar Islands",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chandigarh",
  "Chhattisgarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Delhi",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Ladakh",
  "Lakshadweep",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Puducherry",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
]

export const dateFormat = d => {
  let date = new Date(d)

  // console.log(formattedDate) // Outputs: "02/02/2024 09:40:51"
  
  return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`
}
export const walletBalance = ref(0)
export const fetchWalletBalance = async () => {
  try {
    var {
      data: walletData,
      execute: fetchWalletBalance,
    } = await useLiveApi(createUrl('/wallet-balance'))

    if(walletData.value) {
      const balance = computed(() => walletData.value.balance || 0)

      walletBalance.value = balance.value
      console.log("Wallet balance: "+walletBalance.value)
    }
  } catch (error) {
    console.error("An error occurred: ", error)
    
    return null
  }
}

// setInterval(fetchBalance, 10000)


export const buildUrl = endpoint => {
  let subdomain = useCookie('subdomain').value || ''
  const url = import.meta.env.VUE_APP_BASE_URL.replace('://', '://' + subdomain + '.') || '/api'
  
  return url + "/"+endpoint

}
