<script lang="js" setup>
import { ref, onMounted, Ref } from "vue";
import { fetchGetApi, fetchPostApi } from "@/util/api";

const allData = ref([]);
import Swal from 'sweetalert2';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { defineAsyncComponent } from 'vue';
import { Modal } from 'bootstrap';
import { is } from "date-fns/locale";
import Multiselect from 'vue-multiselect';


const modalCode = defineAsyncComponent(() => import("@/components/roles/modalCode.vue"));
const elementsPerPage = ref(25);
const currentPage = ref(1);
const filterQuery = ref("");
const toast = useToast();

const departmentName = ref('');
const departmentDescription = ref('');
onMounted(async () => {
    // getData()
});

function getData() {
    fetchGetApi('api/category/get/all').then((response) => {
        console.log(response.data);
        if (response && response.data && response.data.length > 0) {
            const sites = response.data
            console.log(sites);
            allData.value = sites;
            console.log(allData.value);
        } else {
            toast.error('Failed to fetch data')
        }
    });
}


function num_pages() {
    const query = filterQuery.value.toLowerCase();

    // Ensure allData.value is an array
    const data = Array.isArray(allData.value) ? allData.value : [];

    const filteredData = data.filter(kyt =>
        kyt.name.toLowerCase().includes(query) ||
        kyt.name.toLowerCase().includes(query)
    );

    return Math.ceil(filteredData.length / elementsPerPage.value);
}

function change_page(page) {
    currentPage.value = page;
}

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

function nextPage() {
    if (currentPage.value < num_pages()) {
        currentPage.value++;
    }
}

function confirmDelete(id) {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            deleteDepartment(id);
        }
    })
}
const isEditt = ref(false);
const dataa = ref({});

const modal = ref();
function showModal(isEdit = false, data = {}) {
    if (isEdit && data) {
        isEditt.value = true;
        dataa.value = data;
        departmentName.value = data.name;
        departmentDescription.value = data.description;
    } else { isEditt.value = false; }
    modal.value = new Modal(document.getElementById('grid-modal'));
    modal.value.show();
}
function hideModal() {
    isEditt.value = false;
    dataa.value = {};
    departmentName.value = '';
    departmentDescription.value = '';

    modal.value.hide();
}
function deleteDepartment(id) {
    fetchGetApi('api/delete/category/' + id).then((response) => {
        console.log(response);
        toast.success(response.data['message']);
        getData();
    });
}
function formatDate(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
}

function updateDepartment() {

    if (!departmentName.value) {
        toast.error('Department Name is required');
        return;
    }
    if (!departmentDescription.value) {
        toast.error('Department Description is required');
        return;
    }
    const data = {
        name: departmentName.value,
        description: departmentDescription.value
    }
    console.log(dataa.value);
    var url = isEditt.value ? 'api/category/edit/' + dataa.value._id : 'api/category/create';
    console.log(url);
    // return;
    fetchPostApi(url, data).then((response) => {
        console.log(response);
        toast.success(response.data['message']);
        hideModal();
        getData();
    });
}


</script>
<template>
    <Breadcrumbs main="Apps" title="Area - Project analysis" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">


            <!-- Click (multiple projects) -->

            <div class="row card">
                <div class="table-responsive card-body product-table custom-scrollbar">
                    <form class="no-footer">
                        
                        <table class="display table-striped table-hover table-bordered table" id="basic-1">
                            <thead>
                                <tr>
                                    <th class="sorting_asc" scope="col" style="width: 50px;">SL</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Name of area</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Name of project</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Percentage (last visit)</th>
                                    <th class="sorting_asc text-nowrap" scope="col">No. of visits and no of visits
                                        inception</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Last status</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Total no of manpower</th>
                                    <th class="sorting_asc  text-nowrap" scope="col">Service required</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Visits</th>
                                    <th class="sorting_asc" scope="col">Calls</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Proposal</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Quote</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Karimganj - 788710</td>
                                    <td>
                                        <router-link :to="`../project`" class="text-primary me-2"
                                            title="View" target="_blank" style="cursor: pointer;  font-size:14px;">
                                            Marketing more
                                        </router-link></td>
                                    <td>65</td>
                                    <td>Date range visit - 07/08/2024 to 07/09/2024<br>Inception visit - 12/09/2024</td>
                                    <td>Pending</td>
                                    <td>8</td>
                                    <td>HK,SG</td>
                                    <td>View</td>
                                    <td></td>
                                    <td>Yes</td>
                                    <td>No</td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>

                <ul class="pagination py-2 justify-content-end pagination-primary">
                    <li class="page-item" @click="previousPage">
                        <a class="page-link">Previous</a>
                    </li>
                    <li class="page-item" v-for="i in num_pages()" :key="i" :class="[i == currentPage ? 'active' : '']"
                        @click="change_page(i)">
                        <a class="page-link">{{ i }}</a>
                    </li>
                    <li class="page-item" @click="nextPage">
                        <a class="page-link">Next</a>
                    </li>
                </ul>
            </div>


        </div>
    </div>


</template>