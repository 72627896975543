<template>
  <tapTop /> {{ display }}
  <div class="page-wrapper" :class="display ? 'compact-wrapper' : layoutobj">
    <div class="page-header" :class="{ close_icon: !store.togglesidebar }">
      <Header @clicked="sidebar_toggle"></Header>
    </div>

    <div class="page-body-wrapper">
      <div class="sidebar-wrapper" :class="[{ close_icon: !store.togglesidebar }]">
        <Sidebar @clicked="sidebar_toggle" />
      </div>
      <div class="page-body">
        <transition name="fadeIn" enter-active-class="animated fadeIn">
          <router-view></router-view>
        </transition>
      </div>
      <footerView />
    </div>
  </div>
  <customizer />
</template>
<script lang="ts" setup>
import { layoutClasses } from "@/constants/layout";
import { useMenuStore } from "@/store/menu";
import config from '@/data/layout.json';
import { uselayoutStore } from "@/store/layout";
import { useProductsStore } from "@/store/products";
import footerView from "./footerView.vue";
import tapTop from "./tapTop.vue";
import Header from "@/components/header/indexView.vue";
import Sidebar from "@/components/sidebar/indexView.vue";
import customizer from "@/components/customizer/indexView.vue";

import { ref, onMounted, watch, computed } from "vue";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";

const layoutobj = ref<any>({});
const sidebar_toggle_var = ref<boolean>(false);
let store = useMenuStore();
const storeLayout = uselayoutStore();
const router = useRouter();
const product = useProductsStore();
store.togglesidebar;
const { layout: layout } = storeToRefs(storeLayout);
const width = window.innerWidth
const menuItems = store.data;
const size = ref(0)
const setSize = ref(0)
const screenWidth = ref<number>(0)
const display = ref<boolean>(false)

function sidebar_toggle(value: boolean) {
  sidebar_toggle_var.value = !value;
}
onMounted(() => {

  window.addEventListener('resize', handleScroll);
  layoutobj.value = storeLayout.layout.settings.sidebar_setting;
  localStorage.setItem("cartItem", JSON.stringify(product.cart));
});
function handleScroll() {
  if (window.innerWidth <= 1199) {
    display.value = true;
  } else {
    display.value = false;
  }
}
watch(
  () => layout,
  () => {
    layoutobj.value = storeLayout.layout.settings.sidebar_setting;
  },
  { deep: true },
);
watch(
  () => "router",
  () => {

    if ((window.innerWidth < 991 && storeLayout.layout.settings.layout === 'Horizontal')) {
      const newlayout = JSON.parse(JSON.stringify(layoutobj).replace('horizontal-wrapper', 'compact-wrapper'));
      layoutobj.value = JSON.parse(JSON.stringify(newlayout))[storeLayout.layout.settings.layout];
    } else {
      layoutobj.value = JSON.parse(JSON.stringify(layoutobj))[storeLayout.layout.settings.layout];
    }
  }
)
</script>
