<template>
    <div class="card">
        <div class="card-body">
            <form @submit.prevent="handleSubmit">
                <div class="d-flex justify-content-between align-items-center mb-4">
                    <button type="button" class="btn btn-secondary" @click="handleBack">
                        <i class="fa fa-arrow-left me-2"></i>
                        Back to List
                    </button>
                </div>

                <div class="row">
                    <!-- Picture Upload -->
                    <div class="col-md-3 text-center mb-4">
                        <div class="picture-container">
                            <img :src="previewUrl || '/path/to/default-avatar.png'"
                                class="rounded-circle profile-pic mb-2" alt="Employee Picture">
                        </div>
                    </div>

                    <!-- Form Fields -->
                    <div class="col-md-9">
                        <div class="row">
                            <!-- Name -->
                            <div class="col-md-6 mb-3">
                                <label class="form-label">Name</label>
                                <input type="text" class="form-control" :value="employee.name" readonly>
                            </div>

                            <!-- Employee Code -->
                            <div class="col-md-6 mb-3">
                                <label class="form-label">Employee Code</label>
                                <input type="text" class="form-control" :value="employee.code" readonly>
                            </div>

                            <!-- Site Name -->
                            <div class="col-md-6 mb-3">
                                <label class="form-label">Site Name</label>
                                <input type="text" class="form-control" :value="employee.siteName" readonly>
                            </div>

                            <!-- Department -->
                            <div class="col-md-6 mb-3">
                                <label class="form-label">Department</label>
                                <input type="text" class="form-control" :value="employee.department" readonly>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Uniform Items Selection -->
                <div class="mt-4">
                    <h5 class="mb-3">Select Uniform Items</h5>
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Item Name</th>
                                    <th>Size</th>
                                    <th>Quantity</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in selectedItems" :key="index">
                                    <td>
                                        <select class="form-select" v-model="item.name">
                                            <option value="">Select Item</option>
                                            <option v-for="uniformItem in uniformItems" :key="uniformItem"
                                                :value="uniformItem">
                                                {{ uniformItem }}
                                            </option>
                                        </select>
                                    </td>
                                    <td>
                                        <select class="form-select" v-model="item.size">
                                            <option value="">Select Size</option>
                                            <option v-for="size in sizes" :key="size" :value="size">
                                                {{ size }}
                                            </option>
                                        </select>
                                    </td>
                                    <td>
                                        <input type="number" class="form-control" v-model="item.quantity" min="1">
                                    </td>
                                    <td>
                                        <button type="button" class="btn btn-danger btn-sm" @click="removeItem(index)">
                                            Remove
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <button type="button" class="btn btn-secondary btn-sm mt-2" @click="addItem">
                        Add Item
                    </button>
                </div>

                <!-- Submit Button -->
                <div class="text-end mt-4">
                    <button type="submit" class="btn btn-primary">
                        Issue Uniform
                    </button>
                </div>
            </form>
        </div>
    </div>
    <finance-details-modal v-if="showFinanceModal" :employee="employee" :items="selectedItems"
        :is-unavailable="isItemsUnavailable" @close="showFinanceModal = false" @order-placed="handleOrderPlaced" />
</template>

<script lang="ts" setup>
import { ref, defineAsyncComponent } from 'vue';
import { useToast } from 'vue-toast-notification';
import { useRouter } from 'vue-router';
const FinanceDetailsModal = defineAsyncComponent(() => import('./finance-details-modal.vue'));

const router = useRouter();

const handleBack = () => {
    router.push('/uniform/issue');
};

interface UniformItem {
    name: string;
    size: string;
    quantity: number;
}

// Sample employee data
const employee = {
    name: 'John Doe',
    code: 'EMP001',
    siteName: 'Site A',
    department: 'Operations',
    picture: 'https://www.w3schools.com/howto/img_avatar.png'
};

const toast = useToast();
const pictureFile = ref<File | null>(null);
const previewUrl = ref<string>(employee.picture || '');
const showFinanceModal = ref(false);
const isItemsUnavailable = ref(true);

// Uniform items data
const uniformItems = [
    'T-Shirt',
    'Pants',
    'Jacket',
    'Cap',
    'Shoes',
    'Belt'
];

const sizes = ['XS', 'S', 'M', 'L', 'XL', 'XXL'];
const selectedItems = ref<UniformItem[]>([
    { name: '', size: '', quantity: 1 }
]);

const handlePictureUpload = (event: Event) => {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
        pictureFile.value = input.files[0];
        previewUrl.value = URL.createObjectURL(input.files[0]);
    }
};

const addItem = () => {
    selectedItems.value.push({ name: '', size: '', quantity: 1 });
};

const removeItem = (index: number) => {
    selectedItems.value.splice(index, 1);
};

const handleSubmit = () => {
    // Your existing validation code...

    if (isItemsUnavailable.value) {
        showFinanceModal.value = true;
    } else {
        // Process direct issue
        toast.success('Uniform issued successfully');
    }
};

const handleOrderPlaced = () => {
    showFinanceModal.value = false;
    toast.success('Order placed successfully');
    router.push('/uniform/issue');
};
</script>

<style scoped>
.profile-pic {
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.picture-container {
    position: relative;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.upload-btn-wrapper input[type=file] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
}
</style>